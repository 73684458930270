import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent as SecondDashboardComponent } from './dashboard/dashboard.component';
import { HeaderLayoutComponent } from './layout/header-layout/header-layout.component';
import { SidebarLayoutComponent } from './layout/sidebar-layout/sidebar-layout.component';
import { MastersComponent } from './admin/masters/masters.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { TicketsComponent } from './tickets/tickets.component';
import { MemberDetailsContainerComponent } from './members/member-details-container/member-details-container.component';
import { HistoryComponent } from './history/history.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { InformationComponent } from './information/information.component';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: HeaderLayoutComponent,
  //   children: [
  //     { path: '', component: SecondDashboardComponent, pathMatch: 'full' },
  //   ],
  // },
  {
    path: '',
    component: SecondDashboardComponent,
    children: [
      {
        path: '',
        component: AdminComponent,
      },
      {
        path: 'fe',
        loadChildren: () => import('./members/members.module').then(m => m.MembersModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'masters',
        component: MastersComponent
      },
      {
        path: 'health-check',
        component: HealthCheckComponent
      },
      {
        path: 'tickets',
        component: TicketsComponent
      },
      {
        path: 'history',
        component: HistoryComponent
      },
      {
        path: 'analytics',
        component: AnalyticsComponent
      },
      {
        path: 'information',
        component: InformationComponent
      }
    ]

  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  }
  ,
  {
    path: 'members',
    component: MemberDetailsContainerComponent
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
