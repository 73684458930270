import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { SocketService } from 'src/app/services/socket.service';
import { UserServiceService } from 'src/app/services/user-service.service';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { AssignedTasksComponent } from './assigned-tasks/assigned-tasks.component';
import { TicketsListComponent } from './tickets-list/tickets-list.component';

@Component({
  selector: 'app-member-details-container',
  templateUrl: './member-details-container.component.html',
  styleUrls: ['./member-details-container.component.scss'],
})
export class MemberDetailsContainerComponent {
  @ViewChild(BasicInfoComponent) basicInfo!: BasicInfoComponent;
  @ViewChild(AssignedTasksComponent) assignedTasks!: AssignedTasksComponent;
  @ViewChild(TicketsListComponent) ticketsList!: TicketsListComponent;
  public showChatWindow: any;
  public queryParams: any;
  public ticketDataLoading: boolean = false;
  ticket_id: any = null;
  user_id: any = null;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private userService: UserServiceService,
    private socket: SocketService
  ) {
    if (!userService.userDetails) {
      userService.setLoginUserDetails();
    }
  }

  user_data: any = null;
  ticket_list: any = null;
  getUserData = (connectSockets: boolean = false) => {
    this.userService.getTicketList(this.user_id).subscribe((data) => {
      if (data.code === 'success') {
        this.ticket_list = data.result[0];
        this.user_data = data.user_list;
        this.user_data['id'] = this.user_id;
        if (!this.ticket_id && typeof this.ticket_list === 'object') {
          const keys = Object.keys(this.ticket_list);
          for (let i = 0; i < keys?.length; i++) {
            const key = keys[i];
            const tickets = this.ticket_list[key];
            if (tickets.list && tickets.list?.length > 0) {
              this.ticket_id = tickets.list[0].ticket_id;
              this.getTicketData(connectSockets);
              break;
            }
          }
        }
        console.log({ data }, this.ticket_list);
      }
    });
  };
  ngOnDestroy(): void {
    if (this.socketConnect) this.socketConnect.unsubscribe();
    this.unSubscribe();
  }
  ngAfterContentInit() {
    this.activateSocketConnection();
    this.route.queryParams.subscribe((queryParams: any) => {
      if (queryParams && queryParams?.chatWindow) {
        this.showChatWindow = true;
      } else {
        this.showChatWindow = false;
      }
      this.queryParams = queryParams;
      this.route.params.subscribe((params: any) => {
        if (params.user_id) {
          this.user_id = parseInt(params.user_id);
          this.getUserData(!params.ticket_id ? true : false);
        }
        if (params.user_id && params.ticket_id) {
          this.ticket_id = parseInt(params.ticket_id);
          this.getTicketData(true);
        }

        if (
          queryParams &&
          queryParams?.callWindow &&
          queryParams?.at &&
          params.user_id
        ) {
          const at = parseFloat(queryParams.at);
          const current = new Date().valueOf() / 1000;
          if (current - at < 2) {
            setTimeout(() => {
              this.ticketsList?.openCallWindow(parseInt(params.user_id), false);
            }, 1000);
          }
        }
      });
    });
    this.commonService.getChatWindow().subscribe((data) => {
      this.showChatWindow = data;
      console.log('dsf', this.showChatWindow);
    });
  }
  ticket_data: any = null;
  task_list: any = null;
  ticket_site_status: any = [];
  getTicketData(connectSockets: boolean = false) {
    this.ticketDataLoading = true;
    const old_ticket_data = this.ticket_data;
    this.userService
      .getTicketDatails({
        ticket_id: this.ticket_id,
        user_id: this.user_id,
      })
      .subscribe((data) => {
        this.ticketDataLoading = false;
        console.log('data coming for the ticket', data);
        if (data.result?.length === 1) {
          const item = data.result[0];
          this.ticket_data = item.ticket_data[0];
          if (this.user_data && !this.user_data.latitude) {
            this.user_data = { ...item.user_details[0], ...this.user_data };
            this.user_data['id'] = this.user_id;
          }
          this.task_list = item.task_list;
          this.ticket_site_status = item.ticket_site_status;
          // this.ticket_site_status = [
          //   {
          //     status: 'Meter Reader Logging',
          //     sla: '',
          //     time: '01:46 PM, 08 MAY 2023',
          //   },
          // ];
          setTimeout(() => {
            this.basicInfo.setIntitialData();
          }, 100);

          if (connectSockets) {
            this.updateSubscriptions(old_ticket_data, this.ticket_data);
          }
        }
      });
  }

  onTicketClick(ticket_id: number) {
    this.ticket_id = ticket_id;
    this.basicInfo.clearMap();
    // this.ticket_data = {};
    // this.user_data = {};
    // this.getTicketData();
  }

  socketObj: any = null;
  subscribe = (data: any): any => {
    if (data.sla_exceeded) {
      const findStatus = this.ticket_site_status.find(
        (item: any) => parseInt(item.status_id) === data.ticket_site_status
      );
      if (findStatus) {
        findStatus.task_exceed_flag = true;
        findStatus.task_exceed_socket = true;
      }
      this.basicInfo.setIntitialData();
      return null;
    }
    this.getUserData();
    this.getTicketData();
    this.assignedTasks && this.assignedTasks.getAssignedTaskData();
  };

  socketConnect: any = null;
  activateSocketConnection = (): any => {
    if (this.socket.isConnected) {
      this.socketObj = this.socket.get_socket();
      this.subscribeSockets();
    }
    this.socketConnect = this.socket.onSocketConnect$.subscribe(
      ({ connected }) => {
        if (connected) {
          this.socketObj = this.socket.get_socket();
          this.subscribeSockets();
        } else this.unSubscribe();
      }
    );
  };
  unSubscribe = () => {
    if (this.ticket_data) {
      this.socket.leaveRoom('ticket-update-' + this.ticket_data.id);
      this.socketObj?.removeListener(
        'ticket-status-update' + this.ticket_data.id,
        this.subscribe
      );
    }
  };
  subscribeSockets = () => {
    if (this.ticket_data) {
      this.socket.joinRoom('ticket-update-' + this.ticket_data.id);
      this.socketObj.on(
        'ticket-status-update-' + this.ticket_data.id,
        this.subscribe
      );
    }
  };
  updateSubscriptions = (old_ticket_data: any, ticket_data: any): any => {
    if (!this.socket.isConnected) {
      return false;
    }
    if (!this.socketObj) {
      this.socketObj = this.socket.get_socket();
    }
    if (old_ticket_data) {
      this.socket.leaveRoom('ticket-update-' + old_ticket_data.id);
      this.socketObj?.removeListener(
        'ticket-status-update' + old_ticket_data.id,
        this.subscribe
      );
    }
    this.socket.joinRoom('ticket-update-' + ticket_data.id);
    this.socketObj.on('ticket-status-update-' + ticket_data.id, this.subscribe);
  };
}
