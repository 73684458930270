<div class="chat-wrapper">
  <div class="chat-list-block">
    <div class="back-icon">
      <mat-icon (click)="backToList()">arrow_back_ios</mat-icon>
    </div>
    <div class="profile-photo">
      <img
        [src]="user_data?.image"
        onerror="this.src='/assets/icons/maps/engineer.png'"
      />
    </div>
    <div class="person-details">
      <div class="person-name">
        {{
          user_data.user_first_name +
            (user_data.user_last_name ? " " + user_data.user_last_name : "")
        }}
      </div>
      <div class="last-message">Site Engineer</div>
    </div>
    <div class="social-connect-icons">
      <mat-icon (click)="chatNow()"> chat_bubble</mat-icon>
      <mat-icon (click)="openCallWindow(user_data.id)">videocam</mat-icon>
      <!-- <mat-icon>phone_in_talk</mat-icon> -->
    </div>
  </div>
  <div class="chat-list-wrapper">
    <div class="message-wrapper" *ngFor="let chat of chat_list">
      <div
        [ngClass]="{
          'chat-message': true,
          left: chat.sender_id !== login_user_id,
          right: chat.sender_id === login_user_id
        }"
        title="{{ chat.created_at }}"
      >
        {{ chat.message }}
        <span class="chat-message-time">{{
          chat.created_at | date : "yyyy-MM-dd hh:mm a"
        }}</span>
      </div>
    </div>
  </div>
  <div class="text-message-wrapper">
    <input
      type="text"
      placeholder="Enter Your Message.."
      [(ngModel)]="message"
    />
    <mat-icon (click)="sendMessage()">send</mat-icon>
  </div>
</div>
