import { Component, Inject, Injectable, NgZone } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { UserServiceService } from 'src/app/services/user-service.service';
import { TemplateRef, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { TicketsService } from '../tickets.service';
import { SocketService } from '../services/socket.service';
import { CommonService } from '../services/common.service';
import { states } from '../services/constants/us-states-list';
@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss'],
})
export class DialogPopupComponent {
  @ViewChild('addresstext') addresstext: any;
  masterForm: any = FormGroup;
  ticketForm: any = FormGroup;
  operatorForm: any = FormGroup;
  taskForm: any = FormGroup;
  siteForm: any = FormGroup;
  healthForm: any = FormGroup;
  assignTasks: any;
  operators: any;
  priority: any;
  siteEngineer: any;
  siteDataOperator: any;
  message: any = '';
  loader: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isImageLoading: boolean = false;
  public selectedSiteLocation = '';
  today: any;
  todayDate: any;
  todayDay: any;
  autocompleteInput: string = '';
  autocomplete: any;
  statsList = states;
  constructor(
    private dialogRef: MatDialogRef<DialogPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    private userService: UserServiceService,
    public dialog: MatDialog,
    private ticketService: TicketsService,
    private socket: SocketService,
    private commonService: CommonService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.today = new Date();
    this.todayDate = new Date(new Date().getTime());
    const array = this.todayDate.toISOString().split('-');
    const year = array[0];
    const month = array[1];
    const date = array[2].split('T')[0];
    this.todayDay = date - 1;
    console.log('I am edit data', this.data);



    this.masterForm = this.fb.group({
      employee_id: new FormControl('', [Validators.required]),
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9_-]{10,12}")]),
      user_type: new FormControl(''),
      status: new FormControl('0'),
      image: new FormControl(),
      password: new FormControl('site'),
      contract_end_date: ['', Validators.required],
      contract_start_date: [new Date()],
      project: [''],
      vendor_info: [''],
      report_manager: [''],
    });

    this.ticketForm = this.fb.group({
      site_engineer_id: ['', Validators.required],
      operator: ['', Validators.required],
      site_location: ['', Validators.required],
      assigned_task: ['', Validators.required],
      priority: ['', Validators.required],
    });

    this.operatorForm = this.fb.group({
      operator_name: new FormControl('', [Validators.required]),
    });

    this.taskForm = this.fb.group({
      task: new FormControl('', [Validators.required]),
      task_code: new FormControl('', [Validators.required]),
      image: new FormControl('')
    });

    this.siteForm = this.fb.group({
      operator_id: ['', [Validators.required]],
      site_code: ['', [Validators.required]],
      site_location: this.fb.group({
        street_address: ['', [Validators.required]],
        city_line: ['', [Validators.required]],
        state: ['', [Validators.required]],
        country: ['USA', [Validators.required]],
        pincode: ['', [Validators.required]],
      })

      // site_latitude: new FormControl('', [Validators.required]),
      // site_longitude: new FormControl('', [Validators.required]),
    });

    this.healthForm = this.fb.group({
      operator_id: new FormControl('', [Validators.required]),
      site_code: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
    });

    if (this.data.tktData) {
      this.assignTasks = this.data.tktData['result'][0]['assign_tasks'];
      this.operators = this.data.tktData['result'][0]['operator'];
      this.operators.forEach((data: any) => {
        if (data.operator_name.toLowerCase() === "t mobile") {
          data.icon = 't-mobile.png'
        } else if (data.operator_name.toLowerCase() === "verizon") {
          data.icon = 'verizon.png'
        } else if (data.operator_name.toLowerCase() === "at&t" || data.operator_name.toLowerCase() === "att") {
          data.icon = 'att.png'
        }
      })
      console.log("I am operators", this.operators);

      this.priority = this.data.tktData['result'][0]['priority'];
      this.siteEngineer = this.data.tktData['result'][0]['site_engineer'];
    }

    if (this.data.optData) {
      this.operators = this.data.optData;
    }

    if (this.data.form === 'health') {
      this.loader = true;
    }
    if (this.data.form === 'tickets' && this.data.formData) {
      this.editTicketData(this.data.formData);
    }

    if (this.data.form === 'operators' && this.data.formData) {
      this.editOperatorsData(this.data.formData);
    }

    if (this.data.form === 'master' && this.data.masterData) {
      this.editMasterData(this.data.masterData);
    }

    if (this.data.form === 'sites') {
      setTimeout(() => {
        this.googleInputAutocomplete()

      }, 1000)
    }
  }

  googleInputAutocomplete() {
    let autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        this.onPlaceChange(autocomplete.getPlace());
      });
    });

    //this.siteForm.get('site_location').setValue(this.autocomplete)
  }

  onPlaceChange(place: google.maps.places.PlaceResult) {

    console.log({ place })


  }
  searchStates(event: any) {
    let value = event.target.value;
    console.log({ value });
    if (value) {
      this.statsList = states.filter((option: any) => option.label.toLowerCase().includes(value));
    } else {
      this.statsList = states;
    }

  }


  getCurrentLocation() {
    navigator.geolocation.watchPosition(res => {
      console.log(res);
      this.commonService.getAddress(res.coords.latitude, res.coords.longitude).subscribe((data) => {
        console.log(data.plus_code.compound_code);
        this.siteForm.get('site_location').setValue(data.plus_code.compound_code);
      })
    })

  }
  private editOperatorsData(formData: any) {
    this.operatorForm.get('operator_name').setValue(formData[0].operator_name);
  }

  private editTicketData(ticketData: any) {
    this.ticketForm
      .get('site_engineer_id')
      .setValue(ticketData.site_engineer_id);

    this.ticketForm.get('operator').setValue(ticketData.operator);
    this.getSites({ value: ticketData.operator }, ticketData);

    //this.ticketForm.get('site_location').setValue(ticketData.site_location);
  }

  private editMasterData(masterData: any) {
    console.log('master Data', masterData);
    this.masterForm.patchValue(masterData);
  }

  public closeMe() {
    this.dialogRef.close();
  }

  uploadImage(e: any) {
    console.log(e.target.files[0]); // outputs the first file
    let img = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsDataURL(img);
    fileReader.onload = () => {
      let result = fileReader.result?.toString();
      console.log('', result);

      this.masterForm.get('image').setValue(result ?? '');
    };
  }
  submitMaster(templateRef: TemplateRef<any>) {
    this.masterForm.get('image').setValue(this.croppedImage ?? '');
    this.dialogRef.close();
    this.userService
      .createEmployee(this.masterForm.value)
      .subscribe((data: any) => {
        this.message = data.message;
        this.dialog.open(templateRef);
      });
  }

  submitTicket(templateRef: TemplateRef<any>, viewType?: string) {
    if (viewType === 'add') {
      this.userService
        .createTicket({
          ...this.ticketForm.value,
          local_admin: this.userService.userDetails.id,
        })
        .subscribe((data: any) => {
          console.log('ticket creation resp', { data });
          this.message = data.message;
          this.dialog.open(templateRef);
          this.socket.socket.emit('ticket-status', {
            ticket_site_status: 0,
            user_id: this.ticketForm.value.site_engineer_id,
            ticket_id: data.result?.id,
          });
          this.dialogRef.close();
        });
    } else if (viewType === 'edit') {
      const ticketData = this.ticketForm.value;
      ticketData.id = this.data.ticketId;
      ticketData.site_engineer = ticketData.site_engineer_id,
        ticketData.operator_id = ticketData.operator,
        ticketData.site_id = ticketData.site_location,
        delete ticketData.site_engineer_id;
      delete ticketData.operator;
      delete ticketData.site_location;
      this.ticketService
        .updateTicket(ticketData)
        .subscribe((data: any) => {
          console.log('ticket creation resp edit', { data });
          this.message = data.message;
          this.dialog.open(templateRef);
          this.dialogRef.close();
        });
    }
  }
  onSiteIdSelection(event: any) {
    this.selectedSiteLocation = this.siteDataOperator.filter(
      (data: any) => data.id === event.value
    )[0].site_location;
  }

  getSites(e: any, ticketData?: any) {
    console.log(e);
    this.userService.getSiteData(e.value).subscribe((data: any) => {
      this.siteDataOperator = data['result'];
      if (ticketData) {
        this.ticketForm.get('site_location').setValue(ticketData.site_location);
        this.selectedSiteLocation = this.siteDataOperator.filter(
          (data: any) => data.id === ticketData.site_location
        )[0].site_location;

        this.ticketForm
          .get('assigned_task')
          .setValue(JSON.parse(ticketData.assigned_task));
        this.ticketForm.get('priority').setValue('' + ticketData.priority);
      }
    });
  }

  get f() {
    return this.masterForm.controls;
  }

  submitOperator(templateRef: TemplateRef<any>) {
    this.dialogRef.close();
    if (this.data.form === 'operators' && this.data.formData) {
      const operatorsData = this.data.formData[0];
      operatorsData.operator_name = this.operatorForm.get('operator_name').value;
      this.userService.updateOperator(this.data.formData[0]).subscribe((data: any) => {
        this.message = "Operators Updated Successfully";
        this.dialog.open(templateRef);
      })
    } else {
      this.userService
        .createOperator(this.operatorForm.value)
        .subscribe((data: any) => {
          console.log({ data });
          this.message = data.message;
          this.dialog.open(templateRef);
        });
    }

  }

  submitTask(templateRef: TemplateRef<any>) {
    this.dialogRef.close();
    this.taskForm.get('image').setValue(this.croppedImage);
    console.log("test", this.taskForm.value)
    this.userService.createTask(this.taskForm.value).subscribe((data: any) => {
      this.message = data.message;
      this.dialog.open(templateRef);
    });
  }

  submitSite(templateRef: TemplateRef<any>) {
    const siteValues = this.siteForm.value;
    this.dialogRef.close();
    const sendValue = {
      operator_id: siteValues.operator_id,
      site_code: siteValues.site_code,
      site_location: siteValues.site_location.street_address + "," + siteValues.site_location.city_line + "," + siteValues.site_location.state + "," + siteValues.site_location.country + "," + siteValues.site_location.pincode
    }
    this.userService.createSite(sendValue).subscribe((data: any) => {
      console.log({ data });
      this.message = data.message;
      this.dialog.open(templateRef);
    });
  }

  fileChangeEvent(event: any): void {
    this.isImageLoading = true;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log('Image cropped:', this.croppedImage);
    this.masterForm.get('image').setValue(this.croppedImage);
  }

  imageLoaded() {
    this.isImageLoading = false;
    // Image has been loaded
  }

  loadImageFailed() {
    // Error while loading image
  }

  cropImage() {
    // Perform any additional logic when the image is cropped
  }

  onTaskEnter(event: any) {

      if (event.charCode === 47) {
        return false;
      } else {
        return true;
      }
  }

  copyData(event: any) {
    setTimeout(() => {
      let value = event.target.value.replace(/\//g, "");
      this.taskForm.get('task').setValue(value);
    })
  }
}
