import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableSkeltonLoaderComponent } from './table-skelton-loader/table-skelton-loader.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';



@NgModule({
  declarations: [TableSkeltonLoaderComponent],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule
  ], 
  exports: [
    TableSkeltonLoaderComponent
  ]
})
export class SharedModule { }
