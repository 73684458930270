<form [formGroup]="masterForm" class="masters-popup" *ngIf="data.form === 'master'">

  <h2 class="dialog-title">{{data.masterData ? 'Edit GC' : 'Add GC'}}</h2>
  <div mat-dialog-content class="AAA">
    <div class="left-col">
      <div class="image-crop-wrapper">
        <div class="loader-wrapper" *ngIf="isImageLoading">
          <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
        </div>
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="2/2"
          [roundCropper]="true" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
        <div class="default-image-wrapper">
          <img [src]="'/assets/icons/maps/engineer.png'" *ngIf="croppedImage === '' && !isImageLoading" />
        </div>
        <div class="upload-btn-wrapper">
          <button class="btn">Upload a file</button>
          <input type="file" (change)="fileChangeEvent($event)" />

        </div>
        <div class="profile-display-wrapper">
          <img [src]="croppedImage" *ngIf="croppedImage" />
        </div>
      </div>
    </div>
    <div class="right-col">
      <mat-form-field appearance="outline">
        <mat-label>GC ID</mat-label>
        <input matInput formControlName="employee_id" placeholder="Enter GC ID">
        <mat-error *ngIf="masterForm.get('employee_id').hasError('required')">GC ID Is Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="first_name" placeholder="Enter First Name">
        <mat-error *ngIf="masterForm.get('first_name').hasError('required')">First Name Is Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="last_name" placeholder="Enter Last Name">
        <mat-error *ngIf="masterForm.get('last_name').hasError('required')">Last Name Is Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Role</mat-label>
        <mat-select formControlName="user_type">
          <mat-option value="2">
            Site Engineer
          </mat-option>
        </mat-select>
        <mat-error *ngIf="masterForm.get('user_type').hasError('required')">User Type Is Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input matInput formControlName="email" placeholder="Enter Email Address">
        <mat-error *ngIf="masterForm.get('email').hasError('required')">Email ID Is Required</mat-error>
        <mat-error *ngIf="masterForm.get('email').hasError('pattern')">Please Enter A Valid Email</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Contact Number</mat-label>
        <input matInput formControlName="phone" placeholder="Enter Contact Number" maxlength="10">
        <mat-error *ngIf="masterForm.get('phone').hasError('required')">Phone Number Is Required</mat-error>
        <mat-error *ngIf="masterForm.get('phone').hasError('pattern')">Please Enter A Valid Phone Number</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Contract End Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="contract_end_date">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker startView="multi-year" [startAt]="todayDate"></mat-datepicker>
        <mat-error *ngIf="masterForm.get('contract_end_date').hasError('required')">Contract End Date Is
          Required</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="basic" class="ms-auto" [mat-dialog-close]="data.animal">Cancel</button>
    <button class="ms-3" color="primary" mat-raised-button cdkFocusInitial (click)="submitMaster(callAPIDialog)"
      [disabled]="!masterForm.valid">Submit</button>
  </div>
</form>

<form [formGroup]="ticketForm" *ngIf="data.form === 'tickets'" class="ticket-popup">
  <h1 mat-dialog-title>{{data.form === 'tickets' && data.formData ? 'Edit Ticket' : 'Create New Ticket'}} </h1>
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Site Engineer</mat-label>
      <mat-select formControlName="site_engineer_id">
        <mat-option *ngFor="let se of siteEngineer" [value]="se.id">
          {{se.first_name}} {{se.last_name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="ticketForm.get('site_engineer_id').hasError('required')">Site Engineer Is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Operator</mat-label>
      <mat-select formControlName="operator" (selectionChange)="getSites($event)">
        <mat-option *ngFor="let op of operators" [value]="op.id">
          <div class="flex-wrap"><span class="drop-down-icon" *ngIf="op.icon"><img alt="{{op.operator_name}}"
                src="../../assets/dropdown-icons/{{op.icon}}"></span> <span
              [class.hide]="op.icon">{{op.operator_name}}</span></div>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="ticketForm.get('operator').hasError('required')">Operator Is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Site Id</mat-label>
      <mat-select formControlName="site_location" (selectionChange)="onSiteIdSelection($event)">
        <mat-option *ngFor="let op of siteDataOperator" [value]="op.id">
          {{op.site_code}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="ticketForm.get('site_location').hasError('required')">Site Id Is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Site Location</mat-label>
      <input matInput [value]="selectedSiteLocation" disabled="true">
      <mat-error>Site Location Is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Assign Tasks</mat-label>
      <mat-select formControlName="assigned_task" multiple>
        <mat-option *ngFor="let op of assignTasks" [value]="op.id">
          {{op.task}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="ticketForm.get('assigned_task').hasError('required')">Assign Task Is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Priority</mat-label>
      <mat-select formControlName="priority">
        <mat-option value="0">
          High
        </mat-option>
        <mat-option value="2">
          Low
        </mat-option>
        <mat-option value="1">
          Medium
        </mat-option>
      </mat-select>
      <mat-error *ngIf="ticketForm.get('priority').hasError('required')">Priority Is Required</mat-error>
    </mat-form-field>
    <!-- <mat-form-field class="chip-list" appearance="fill">
            <mat-label>Assign Tasks</mat-label>
            <mat-chip-grid #chipGrid aria-label="Enter fruits">
              <mat-chip-row >
                cfasfafafs
                <button matChipRemove >
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
              <input placeholder="Tasks.." />
            </mat-chip-grid>
          </mat-form-field> -->
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="basic" class="ms-auto" [mat-dialog-close]="data.animal">Cancel</button>
    <button class="ms-3" color="primary" mat-raised-button cdkFocusInitial
      (click)="submitTicket(callAPIDialog, data.formData ? 'edit': 'add' )" [disabled]="!ticketForm.valid">{{
      data.formData ? "Save":
      "Create"}}</button>
  </div>
</form>
<form [formGroup]="operatorForm" *ngIf="data.form === 'operators'" class="ticket-popup">
  <h1 mat-dialog-title>{{this.data.formData ? "Edit" : "Create New"}} Operator</h1>
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Operator</mat-label>
      <input matInput formControlName="operator_name" placeholder="Enter Operator">
      <mat-error>Operator Is Required</mat-error>
    </mat-form-field>
    <mat-error *ngIf="operatorForm.get('operator_name').hasError('required')">Operator Name Is Required</mat-error>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="basic" class="ms-auto" [mat-dialog-close]="data.animal">Cancel</button>
    <button class="ms-3" color="primary" mat-raised-button cdkFocusInitial (click)="submitOperator(callAPIDialog)"
      [disabled]="!operatorForm.valid">{{this.data.formData ? "Save" : "Create"}}</button>
  </div>
</form>

<form [formGroup]="taskForm" *ngIf="data.form === 'tasks'" class="ticket-popup">
  <h1 mat-dialog-title>Create New Task</h1>

  <div mat-dialog-content class="AAA">
    <div class="new-task-wrapper">
      <div class="left-col">
        <div class="image-crop-wrapper">
          <div class="loader-wrapper" *ngIf="isImageLoading">
            <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
          </div>
          <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="2/2"
            [roundCropper]="true" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
            (loadImageFailed)="loadImageFailed()"></image-cropper>
          <div class="default-image-wrapper">
            <img [src]="'/assets/icons/maps/engineer.png'" *ngIf="croppedImage === '' && !isImageLoading" />
          </div>
          <div class="upload-btn-wrapper">
            <button class="btn">Upload Image</button>
            <input type="file" (change)="fileChangeEvent($event)" />

          </div>
          <div class="profile-display-wrapper">
            <img [src]="croppedImage" *ngIf="croppedImage" />
          </div>
        </div>
      </div>
      <div class="right-col">
        <mat-form-field appearance="fill">
          <mat-label>Task</mat-label>
          <input matInput formControlName="task" placeholder="Enter Task" (paste)="copyData($event)" (keypress)="onTaskEnter($event)">
          <mat-error *ngIf="taskForm.get('task').hasError('required')">Task Is Required</mat-error>
        </mat-form-field>
        <span class="info">Please make sure task name is created without forward slash (/).</span>
        <mat-form-field appearance="fill">
          <mat-label>Task Code</mat-label>
          <input matInput formControlName="task_code" placeholder="Enter Task Code">
          <mat-error *ngIf="taskForm.get('task_code').hasError('required')">Task Code Is Required</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="basic" class="ms-auto" [mat-dialog-close]="data.animal">Cancel</button>
    <button class="ms-3" color="primary" [disabled]="!taskForm.valid" mat-raised-button cdkFocusInitial
      (click)="submitTask(callAPIDialog)">Create</button>
  </div>
</form>

<form [formGroup]="siteForm" *ngIf="data.form === 'sites'" class="ticket-popup">
  <h1 mat-dialog-title>Create New Site</h1>
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Operator Id</mat-label>
      <mat-select formControlName="operator_id">
        <mat-option *ngFor="let op of operators" [value]="op.id">
          {{op.operator_name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="siteForm.get('operator_id').hasError('required')">Operator Id Is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Site Code</mat-label>
      <input matInput formControlName="site_code" placeholder="Enter Site Code">
      <mat-error *ngIf="siteForm.get('site_code').hasError('required')">Site Code Is Required</mat-error>
    </mat-form-field>
    <!--     <div class="input-with-icon">
      <mat-form-field appearance="fill">
        <mat-label>Site Location</mat-label>
        <div class="icon-postfix">
          <input matInput formControlName="site_location" id="autocompleteInput" #addresstext
            placeholder="Enter Site Location">
        </div>
        <mat-error *ngIf="siteForm.get('site_location').hasError('required')">Site Location Is Required</mat-error>
      </mat-form-field>
      <mat-icon matTooltip="Get Current Location" (click)="getCurrentLocation()">location_searching</mat-icon>

    </div> -->
    <div class="" formGroupName="site_location">
      <!--    <mat-form-field appearance="fill">
        <mat-label>Address</mat-label>
        <input matInput formControlName="address" placeholder="Enter Site Code">
        <mat-error>Address Is Required</mat-error>
      </mat-form-field> -->
      <div class="input-fields-2">
        <mat-form-field appearance="fill">
          <mat-label>Street Address</mat-label>
          <input matInput formControlName="street_address" placeholder="Enter Site Code">
          <mat-error>Street Address Is Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>City</mat-label>
          <input matInput formControlName="city_line" placeholder="Enter Site Code">
          <mat-error>City Is Required</mat-error>
        </mat-form-field>
      </div>
      <div class="input-fields-2">
        <mat-form-field appearance="fill">
          <mat-label>State</mat-label>
          <mat-select formControlName="state">
            <div class="search-state-wrap"><mat-icon>search</mat-icon>
              <input class="search-box" (keyup)="searchStates($event)" placeholder="Search" matInput>
            </div>
            <mat-option *ngFor="let op of statsList" [value]="op.value">
              {{op.label}}
            </mat-option>
          </mat-select>
          <mat-error>State Is Required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Zip Code</mat-label>
          <input matInput formControlName="pincode" placeholder="Enter Site Code" type="number" max-length="5">
          <mat-error>Zip Code Required</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill" [disabled]="true">
        <mat-label>Country</mat-label>
        <input matInput formControlName="country" placeholder="Enter Site Code" [disabled]="true">
        <mat-error>County Is Required</mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- <mat-form-field appearance="fill">
      <mat-label>Site Latitude</mat-label>
      <input matInput formControlName="site_latitude" placeholder="Enter Task Code">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Site Longitude</mat-label>
      <input matInput formControlName="site_longitude" placeholder="Enter Task Code">
    </mat-form-field> -->
  <div mat-dialog-actions>
    <button mat-stroked-button color="basic" class="ms-auto" [mat-dialog-close]="data.animal">Cancel</button>
    <button class="ms-3" color="primary" [disabled]="!siteForm.valid" mat-raised-button cdkFocusInitial
      (click)="submitSite(callAPIDialog)">Create</button>
  </div>
</form>

<form [formGroup]="healthForm" *ngIf="data.form === 'health'" class="ticket-popup">
  <h1 mat-dialog-title>New Health Check</h1>
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Operator</mat-label>
      <mat-select formControlName="operator_id">
        <mat-option value="">
          VERIZON
        </mat-option>
      </mat-select>
      <mat-error *ngIf="healthForm.get('operator_id').hasError('required')">Operator Id Is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Site Id</mat-label>
      <mat-select formControlName="site_code">
        <mat-option value="">
          CVL01487
        </mat-option>
      </mat-select>
      <mat-error *ngIf="healthForm.get('site_code').hasError('required')">Site Code Is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Request Type</mat-label>
      <mat-select formControlName="type">
        <mat-option value="">
          Fiber Loss
        </mat-option>
      </mat-select>
      <mat-error *ngIf="healthForm.get('type').hasError('required')">Fiber Loss Is Required</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="basic" class="ms-auto" [mat-dialog-close]="data.animal">Cancel</button>
    <button class="ms-3" color="primary" mat-raised-button cdkFocusInitial [mat-dialog-close]="loader">Fetch Pre Check
      Report</button>
  </div>
</form>

<ng-template #callAPIDialog>
  <h1 mat-dialog-title>Status</h1>
  <div mat-dialog-content>
    <p>{{message}}</p>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" class="ms-auto" [mat-dialog-close]="data.animal">Close</button>
    </div>
  </div>
</ng-template>