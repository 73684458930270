export const environment = {
  production: false,
  enVersion: '1.0.0',
  // apiBaseURL: 'http:localhost:8000/api/',
  // callURL: 'http://localhost/',
  // socketBaseURL:'http://localhost:5004',
  apiBaseURL: 'https://gc360-stage-api.exagensolutions.com/api/',
  callURL: 'https://gc360-stage-api.exagensolutions.com/',
  socketBaseURL:'https://gc360-stage-api.exagensolutions.com:5004',
  // callURL: 'https://gc360-dev-api.bayone-telecom.com/',
  // apiBaseURL: 'https://gc360-dev-api.bayone-telecom.com:8000/api/',
  // socketBaseURL:'https://gc360-dev-api.bayone-telecom.com:5004',
  isAuthRequired: false,
  gmaps_api_key:"AIzaSyD2ji2zXqG4FKGrQQ-ydpO5gIUPWgNvl5w",
}


export const firebaseConfig = {

  apiKey: "AIzaSyCfyzANottwdQoEtB6gYooOgEh6Pz6kPcg",

  authDomain: "gc360-master-admin.firebaseapp.com",

  projectId: "gc360-master-admin",

  storageBucket: "gc360-master-admin.appspot.com",

  messagingSenderId: "420456706243",

  appId: "1:420456706243:web:481d59ca064ede762fb276"

};
