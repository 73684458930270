import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import config from 'src/config';
import { environment } from 'src/environments/environment';

const socker_base_url = environment.socketBaseURL;

@Injectable({
  providedIn: 'root',
})
export class ChatServices {
  constructor(private http: HttpClient) {}

  public getChatMessages(user_id: number, sender_id: number): Observable<any> {
    return this.http.get(socker_base_url + '/chat-messages', {
      params: { user_id, sender_id },
    });
  }
}
