import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ChatServices } from '../services/chat.service';
import { MatDialog } from '@angular/material/dialog';
import { CallWindowComponent } from '../call-window/call-window.component';
import { SocketService } from '../services/socket.service';
import { UserServiceService } from '../services/user-service.service';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})
export class ChatWindowComponent {
  @Input() user_data: any = null;
  @Input() ticket_data: any = null;
  constructor(
    private router: Router,
    private service: ChatServices,
    public dialog: MatDialog,
    private socket: SocketService,
    private userService: UserServiceService
  ) {
    this.login_user_id = userService?.userDetails?.id || null;
  }
  login_user_id: number | null = null;
  uuid = Math.floor(Math.random() * 500) + 10;
  backToList() {
    this.router.navigate(['']);
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.get_chat_data();
  }
  // addDateMessages = (data:any[])=>{

  // }
  get_chat_data = (): any => {
    const sender_id = this.userService?.userDetails?.id || null;
    if (!sender_id) {
      return null;
    }
    this.service
      .getChatMessages(this.user_data.id, sender_id)
      .subscribe((res: any) => {
        this.chat_list = res.data;
        this.scrollContainer();
        this.subsribeForSockets(this.user_data);
      });
  };
  chat_list: any[] = [];
  openCallWindow(receiver_id: number, notify: boolean = true) {
    this.userService.user_call_joining = receiver_id;
    const dialogRef = this.dialog.open(CallWindowComponent, {
      data: { notify: notify, receiver_id },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.userService.user_call_joining = null;
    });
  }

  socketObj: any = null;
  subscribe = (data: any) => {
    // handle new data from gc
    this.chat_list.push(data);
    this.scrollContainer();
  };
  scrollContainer = () => {
    const chatWrappers = document.getElementsByClassName('chat-list-wrapper');
    if (chatWrappers.length > 0) {
      setTimeout(() => {
        chatWrappers[0].scrollTop = chatWrappers[0].scrollHeight;
      }, 20);
    }
  };

  socketConnect: any = null;
  subsribeForSockets = (user_data: any) => {
    if (this.socket.isConnected) {
      this.socketObj = this.socket.get_socket();
      this.socketSubscribe(user_data);
    }
    if (!this.socketConnect) {
      this.socketConnect = this.socket.onSocketConnect$.subscribe(
        ({ connected }) => {
          if (connected) {
            this.socketObj = this.socket.get_socket();
            this.socketSubscribe(this.user_data);
          } else this.unSubscribe();
        }
      );
    }
  };
  socketSubscribe = (user_data: any) => {
    this.socketObj = this.socket.get_socket();
    this.socket.joinRoom('chat-' + user_data.user_id);
    this.socketObj.on('chat-' + user_data.user_id + 'Message', this.subscribe);
  };
  unSubscribe = () => {
    if (this.user_data.user_id && this.socket.isConnected) {
      this.socket.leaveRoom('chat-' + this.user_data.user_id);
      this.socketObj?.removeListener(
        'chat-' + this.user_data.user_id + 'Message',
        this.subscribe
      );
    }
  };

  sendMessage = () => {
    console.log('sendMessage', this.socketObj, this.message);
    if (!this.message) {
      return false;
    }
    const input_data = {
      sender_id: this.userService.userDetails.id,
      receiver_ids: [this.user_data.user_id],
      message: this.message,
      user: {
        name:
          this.userService.userDetails.first_name +
            (this.userService.userDetails.last_name
              ? ' ' + this.userService.userDetails.last_name
              : '') || null,
      },
    };
    const index = this.chat_list.length;
    this.chat_list.push(input_data);
    if (this.socketObj) {
      this.message = '';
      this.socketObj.emit('chat-message', input_data, (data: any) => {
        if (data.status === 'success') {
          this.chat_list[index] = data.data;
        }
      });
    }
    return true;
  };
  message: any = null;
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.socketConnect) this.socketConnect.unsubscribe();
    this.unSubscribe();
  }

  chatNow() {
    this.router.navigate(
      this.ticket_data?.id
        ? ['/engineer', this.user_data.id, 'ticket', this.ticket_data.id]
        : ['/engineer', this.user_data.id],
      {
        queryParams: { chatWindow: '' },
      }
    );
  }
}
