<div class="create-selection-wrapper">
    <div class="options-card-wrapper">
        <h2 class="options-title">Onboarding</h2>

        <div class="options-card-block-wrapper">
            <div class="options-card" (click)="onChipClick(chip)" *ngFor="let chip of adminDashboardChips">
                <div class="person-id"><img src="../../assets/dashboard-icons/{{chip.icon}}.svg"></div>
                {{chip.title}}
            </div>
        </div>
        <h2 class="options-title">Configuration</h2>

        <div class="options-card-block-wrapper">
            <div class="options-card" (click)="onChipClick(chip)" *ngFor="let chip of groupTwoChips">
                <div class="person-id"><img src="../../assets/dashboard-icons/{{chip.icon}}.svg"></div>
                {{chip.title}}
            </div>
        </div>
    </div>
</div>