<div class="members-details-main-wrapper">
  <div class="tickets-wrapper">
    <app-tickets-list *ngIf="showChatWindow !== true" [ticket_data]="ticket_list" [selected_ticket]="ticket_id"
      (ticket)="onTicketClick($event)" [user_data]="user_data" [ticket_details]="ticket_data"></app-tickets-list>
    <app-chat-window *ngIf="showChatWindow === true && user_data" [user_data]="user_data"
      [ticket_data]="ticket_data"></app-chat-window>
  </div>
  <div class="members-details-info-wrapper">

    <div class="basic-info-wrapper">
      <app-basic-info *ngIf="user_data && ticket_data" [ticket_data]="ticket_data"
        [ticket_status_list]="ticket_site_status" [user_data]="user_data" [task_list]="task_list"></app-basic-info>
    </div>
    <div class="assigned-tasks-list-wrapper">
      <app-assigned-tasks [ticketId]="ticket_id" [taskList]="task_list"
        [ticketDataLoading]="ticketDataLoading"></app-assigned-tasks>
    </div>
  </div>
</div>