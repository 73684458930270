import { Component, Input, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { API } from 'src/app/services/constants/api.constants';
import { GlobalService } from 'src/app/services/global.service';
import { TicketTask, assignedTask } from '../models/assignedTask';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-assigned-tasks',
  templateUrl: './assigned-tasks.component.html',
  styleUrls: ['./assigned-tasks.component.scss'],
})
export class AssignedTasksComponent {
  _ticketId: any = null;
  @Input() taskList: any = [];
  @Input() ticketId: any;
  @Input() ticketDataLoading: boolean = false;
  panelOpenState = false;
  public taskData: any = [];
  public selectedTaskData: any = [];
  public dataLoading: boolean = false;
  public allaramCountLength = '';
  constructor(private service: GlobalService, private userService: UserServiceService) { }
  assignedTask: assignedTask = {
    code: '',
    message: '',
    result: [],
  };
  ngOnInit() {
    console.log("assinged", this.taskList)
    this.getAssignedTaskData();
  }
  ngOnChanges() {
    console.log("assinged", this.taskList)
    this.allaramCountLength = this.taskList ? this.taskList.filter((task: any) => task.task_limit_exceed).length : 0;
  }

  getAssignedTaskData() {
    this.dataLoading = true;
    this.userService.getAssignedTaskDetails(this.ticketId).subscribe((data) => {
      this.dataLoading = false;
      this.taskData = data;
    })
  }

  onAccordionOpened(task: any) {
    if (this.taskData && this.taskData?.result && this.taskData?.result[0]) {
      this.selectedTaskData = this.taskData.result[0].ticket_task_list.find((data: any) => data.task_id === task.id);
    }
  }
}
