import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from  '@angular/material/dialog';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss']
})
export class HealthCheckComponent {
  loader : boolean = false;
  fetchReport: boolean = false;
  constructor(public dialog: MatDialog) {}
  ngOnInit(){    

  }
  
  openDialog() {
    this.dialog.open(DialogPopupComponent, {
      data: {
        form: 'health'
      }
    }).afterClosed().subscribe((res)=>{       
      console.log(res);
      this.loader = res;
    });;
  }

  fetchingReport(){
    this.loader = false;
    this.fetchReport = true;
  }

  back(){
    this.loader = false;
    this.fetchReport = false;
  }

}
