import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import config from 'src/config';
import { environment } from 'src/environments/environment';
const base_url = environment.apiBaseURL;

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(private http: HttpClient) { }



  public getTicketDatails(ticketId: any): Observable<any> {
    return this.http.get(base_url + `admin/ticket/edit/?ticket_id=${ticketId}`);
  }

  public updateTicket(ticketData: any): Observable<any> {
    return this.http.post(base_url + `admin/ticket/update/`, ticketData);

  }

  public getHistory(dateObj: any): Observable<any> {
    return this.http.post(base_url + `admin/history/list/`, dateObj)
  }

  public deleteTicket(ticketId: string): Observable<any> {
    return this.http.post(base_url + `admin/ticket/delete/`, { id: ticketId })
  }
}
