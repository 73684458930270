<div class="right-container">
  <div class="routing-container">
    <div class="column column1">
      <h2 class="section-title">Tickets List</h2>
      <div class="chat-list-block">
        <div class="back-icon">
          <mat-icon (click)="backToList()">arrow_back_ios</mat-icon>
        </div>
        <div class="profile-photo">
          <img
            [src]="user_data?.image"
            onerror="this.src='/assets/icons/maps/engineer.png'"
          />
        </div>
        <div class="person-details">
          <div class="person-name">
            {{
              user_data?.user_first_name +
                (user_data?.user_last_name
                  ? " " + user_data?.user_last_name
                  : "")
            }}
          </div>
          <div class="last-message">Site Engineer</div>
        </div>
        <div class="social-connect-icons">
          <mat-icon (click)="chatNow()"> chat_bubble</mat-icon>
          <mat-icon (click)="openCallWindow(user_data.id)">videocam</mat-icon>
          <!-- <mat-icon>phone_in_talk</mat-icon> -->
        </div>
      </div>
      <div class="tkt-sec" id="style-3">
        <p>
          Tickets
          {{
            (ticket_data?.in_progress_list.count || 0) +
              (ticket_data?.completed_list.count || 0) +
              (ticket_data?.cancelled_list.count || 0) +
              (ticket_data?.upcoming_list.count || 0)
          }}
        </p>
        <ul class="inprogress" *ngIf="ticket_data?.in_progress_list?.list">
          <li>
            <p>In Progress ({{ ticket_data?.in_progress_list.count || 0 }})</p>
            <label
              class="ticket d-flex"
              *ngFor="let ticket of ticket_data?.in_progress_list?.list || []"
              (click)="onTicketClick(ticket)"
            >
              <input
                type="radio"
                class="me-3"
                (click)="onCheckBoxChange($event)"
                [checked]="selected_ticket === ticket.ticket_id"
              />
              <span class="left">
                <i class="fa-regular fa-circle-stop orange-text"></i>
                {{ ticket.ticket_name }}
              </span>
              <span class="right ms-auto">
                {{ ticket.operator_name }}
                <i class="fa-solid fa-network-wired mx-2"></i>
                {{ ticket.site_code }}
              </span>
            </label>
          </li>
        </ul>

        <ul class="upcoiming mt-3" *ngIf="ticket_data?.upcoming_list?.list">
          <li>
            <p>Upcoming ({{ ticket_data?.upcoming_list.count || 0 }})</p>
            <label
              class="ticket d-flex"
              *ngFor="let ticket of ticket_data?.upcoming_list?.list || []"
              (click)="onTicketClick(ticket)"
            >
              <input
                type="radio"
                class="me-3"
                (click)="onCheckBoxChange()"
                [checked]="selected_ticket === ticket.ticket_id"
              />
              <span class="left">
                <i class="fa-regular fa-circle-stop orange-text"></i>
                {{ ticket.ticket_name }}
              </span>
              <span class="right ms-auto">
                {{ ticket.operator_name }}
                <i class="fa-solid fa-network-wired mx-2"></i>
                {{ ticket.site_code }}
              </span>
            </label>
          </li>
        </ul>
        <ul class="upcoiming mt-3" *ngIf="ticket_data?.completed_list?.list">
          <li>
            <p>Completed ({{ ticket_data?.completed_list.count || 0 }})</p>
            <label
              class="ticket d-flex"
              *ngFor="let ticket of ticket_data?.completed_list?.list || []"
              (click)="onTicketClick(ticket)"
            >
              <input
                type="radio"
                class="me-3"
                (click)="onCheckBoxChange()"
                [checked]="selected_ticket === ticket.ticket_id"
              />
              <span class="left">
                <i class="fa-regular fa-circle-stop orange-text"></i>
                {{ ticket.ticket_name }}
              </span>
              <span class="right ms-auto">
                {{ ticket.operator_name }}
                <i class="fa-solid fa-network-wired mx-2"></i>
                {{ ticket.site_code }}
              </span>
            </label>
          </li>
        </ul>
        <ul class="upcoiming mt-3" *ngIf="ticket_data?.cancelled_list?.list">
          <li>
            <p>Cancelled ({{ ticket_data?.cancelled_list.count || 0 }})</p>
            <label
              class="ticket d-flex"
              *ngFor="let ticket of ticket_data?.cancelled_list?.list || []"
              (click)="onTicketClick(ticket)"
            >
              <input
                type="radio"
                class="me-3"
                (click)="onCheckBoxChange()"
                [checked]="selected_ticket === ticket.ticket_id"
              />
              <span class="left">
                <i class="fa-regular fa-circle-stop orange-text"></i>
                {{ ticket.ticket_name }}
              </span>
              <span class="right ms-auto">
                {{ ticket.operator_name }}
                <i class="fa-solid fa-network-wired mx-2"></i>
                {{ ticket.site_code }}
              </span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
