<div class="right-container">
  <div class="routing-container">
    <div class="column column2">
      <h2 class="section-title">Basic Info</h2>
      <div class="ticket d-flex">
        <span class="left">
          <i class="fa-regular fa-circle-stop orange-text"></i>
          {{ ticket_data?.ticket }}
        </span>
        <span class="right ms-auto">
          {{ ticket_data.operator }}
          <i class="fa-solid fa-network-wired mx-2"></i>
          {{ ticket_data.site_code }}
        </span>
      </div>
      <div class="status-box">
        <p>Status</p>
        <ul class="status-stages">
          <!-- *ngFor="let status of filteredList(status_list)" [ngClass]="{
              active: status.active === 1,
              completed: status.active === 2
            }"
            used filter for old model type -->
          <li *ngFor="let status of status_list">
            <!-- <span class="icon"><i class="{{ status.fa_icon }}"></i></span> -->
            <span
              class="icon"
              [ngStyle]="{
                color: status.color,
                'background-color': status.color + '44'
              }"
              ><img
                class="image"
                [src]="
                  status.image || 'assets/icons/maps/signal-tower-icon.svg'
                "
            /></span>
            <span class="text">
              <strong
                [ngStyle]="{
                  color: status.color
                }"
              >
                {{ status.header }}
              </strong>
              <div class="sla-wrapper">
                <!-- {{
                status.start_timer === "start" &&
                active_ticket_site_status === status.status_id
                  ? (timeRemaining$ | async | date : "mm:ss" : "UTC")
                  : null
              }} -->
                <!-- <span
                class="{{
                  status.start_timer === 'stop' && status.exceeded
                    ? 'sla-error'
                    : ''
                }}"
                >{{
                  status.time || (status.sla ? "SLA: " + status.sla : "")
                }}</span> -->
                {{ status.time }}
                <span
                  class="sla-chip"
                  *ngIf="status.sla && !status.task_exceed_flag && !status.time"
                  >{{ "SLA:" + status.sla }}</span
                >
                <span
                  class="sla-chip margin-left exceeded"
                  *ngIf="status.task_exceed_flag"
                  >{{
                    status.task_exceed_socket
                      ? "SLA Exceeded"
                      : "SLA Exceeded: " + status.task_exceed_time + "mins"
                  }}</span
                >
              </div>
            </span>
          </li>
        </ul>
      </div>
      <h2 class="section-title">Location - Live Tracking</h2>
      <div class="map-tracking">
        <div class="direction-error" *ngIf="!can_get_map">
          {{
            !can_get_map
              ? "No routes found" +
                (!user_data.latitude ? " (GC log out from App)" : "")
              : null
          }}
        </div>
        <div class="map-container" id="ticket-map"></div>
        <div class="map-track-details">
          <div class="track-time">
            <span
              class="status"
              *ngIf="active_ticket_site_status && active_ticket_site_status > 4"
              >On Site</span
            >
            <span class="time">
              <strong>{{
                active_ticket_site_status && active_ticket_site_status > 4
                  ? "0 mins"
                  : duration
              }}</strong></span
            >
            |
            <strong>{{
              active_ticket_site_status && active_ticket_site_status > 4
                ? "0 miles"
                : distance
            }}</strong>
          </div>
          <div class="track-addr">
            <i class="fa-solid fa-location-dot"></i>
            {{ ticket_data.site_location }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
