import { Component, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TicketsService } from '../tickets.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { UserServiceService } from '../services/user-service.service';
import { MatTabGroup } from '@angular/material/tabs';
export interface PeriodicElement {
  employeeName: string;
  position: number;
  ticketId: number;
  status: string;
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})

export class HistoryComponent {

  @ViewChild('tabs') tabGroup!: MatTabGroup;
  
  ticketDataLoading: boolean = false;
  daysArray: any = [];
  today!: Date;
  sixMonthsAgo!: Date;
  selectedMonth: any;
  selectedYear: any;
  todayDate: any;
  todayDay: any;
  compareDate: any;
  currentMonth: any;
  currentYear: any;
  dataLoading: boolean = false;
  activeIndex: any;
  ticketDetails: any;

  lat = 40.2338612;
  lng = -83.0226057;

  origin: any;
  destination: any;
  waypoints: any = []; /* [
     {location: { lat: 39.0921167, lng: -94.8559005 }},
     {location: { lat: 41.8339037, lng: -87.8720468 }}
  ]; */

  showMap: boolean = false;
  markers: any = [
  ]
  public renderOptions = {
    suppressMarkers: true,
  }

  public markerOptions = {
    origin: {
      icon: '/assets/icons/maps/history-engineer.png',
      width: '20px',
      height: '20px'
    },
    destination: {
      icon: '/assets/icons/maps/history-tower.png',
      width: '20px',
      height: '20px'
    },

  }
  public ELEMENT_DATA: PeriodicElement[] = [
    /* {position: 1, employeeName: 'John', ticketId: 98303, status: 'Completed'},
    {position: 2, employeeName: 'Peter', ticketId: 12123, status: 'In Progress'},
    {position: 3, employeeName: 'James', ticketId: 2323, status: 'Completed'},
    {position: 4, employeeName: 'John', ticketId: 45454, status: 'Completed'},
    {position: 5, employeeName: 'John', ticketId: 435345, status: 'Completed'},
    {position: 6, employeeName: 'John', ticketId: 345, status: 'In Progress'},
    {position: 7, employeeName: 'John', ticketId: 34534, status: 'Completed'},
   */
  ];
  displayedColumns: string[] = ['gc_id', 'gc_name', 'ticket_complete_count', 'sla_exceed'];
  historyData: any = new MatTableDataSource<any>();
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement!: PeriodicElement | null;
  @ViewChild('historyPainator') historyPainator: MatPaginator | undefined;

  constructor(private ticketsService: TicketsService, private userService: UserServiceService) { }
  ngOnInit() {
    this.today = new Date()
    this.todayDate = new Date((new Date().getTime()));
    const array = this.todayDate.toISOString().split('-');
    const year = array[0];
    const month = array[1];
    const date = array[2].split('T')[0]
    this.todayDay = date - 1;
    this.activeIndex = date - 1;
    this.currentYear = year;
    this.currentMonth = month
    this.selectedMonth = month;
    this.selectedYear = year;
    this.daysArray = this.getMonthDays(year, month);

    this.getHistory({ 'date': `${year}-${month}-${date}` });
  }


  getHistory(dateObj: any) {
    this.dataLoading = true;
    this.ticketsService.getHistory(dateObj).subscribe((data) => {
      this.dataLoading = false;
      this.historyData.data = data.result;
      // this.expandedElement = this.historyData.data[0]

    }, (error) => {
      this.dataLoading = false;
      this.historyData.data = [];
    })
  }
  ngAfterViewInit() {
    this.historyData.paginator = this.historyPainator;
    this.tabGroup.selectedIndex = this.activeIndex;

  }
  getMonthDays(year: any, month: any) {
    const daysInMonth: any = new Date(year, month, 0).getDate();



    for (let day = 1; day <= daysInMonth; day++) {
      const selectedMonth = new Date(year, month - 1, day);
      const dayOfWeek = selectedMonth.toLocaleDateString('en-US', { weekday: 'long' }) // Get the day of the week (e.g. Monday)
      const date = selectedMonth.getDate();


      this.daysArray.push({ dayOfWeek, date, selectedMonth, year, month });
    }

    return this.daysArray;
  }
  openDatePicker(dp: any) {
    dp.open();
  }

  closeDatePicker(eventData: any, dp?: any) {
    // get month and year from eventData and close datepicker, thus not allowing user to select date
    dp.close();
  }

  onDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.daysArray = [];
    if (event.value) {
      const array = event.value.toISOString().split('-');
      const year = array[0];
      const month = array[1];
      this.selectedMonth = month;
      this.selectedYear = year;
      const date = array[2].split('T')[0]
      this.todayDay = date;
      this.daysArray = this.getMonthDays(year, month);

    }
  }

  getHistoryData(date: any) {
    const selectedDate = this.daysArray[date.index];
    this.getHistory({ 'date': `${selectedDate.year}-${selectedDate.month}-${selectedDate.date}` })
  }

  getTicketDetails(ticket: any, ticketList: any, userId: any) {
    ticketList.forEach((data: any) => data.isActive = false)
    ticket.isActive = true;
    this.showMap = false;
    setTimeout(() => { this.showMap = true }, 20)
    this.waypoints = [];
    this.origin = { lat: parseFloat(ticket.ticket_live_location[0].latitude), lng: parseFloat(ticket.ticket_live_location[0].longitude) };
    this.destination = { lat: parseFloat(ticket.ticket_live_location[2].latitude), lng: parseFloat(ticket.ticket_live_location[2].longitude) };
    ticket.ticket_live_location.forEach((data: any, index: number) => {
      console.log({ data });
      if (index === 1) {
        this.waypoints.push({
          location: {
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
            iconUrl: 'https://gc360-dev.s3.amazonaws.com/site_status/Viewed'
          }
        })
      }
      this.markers.push({
        lat: parseFloat(data.latitude),
        lng: parseFloat(data.longitude),
        iconUrl: 'https://gc360-dev.s3.amazonaws.com/site_status/Viewed'
      })
    })
    const sendData = {
      ticket_id: ticket.ticket_id,
      user_id: userId
    }
    this.ticketDataLoading = true;
    this.userService.getTicketDatails(sendData).subscribe((data: any) => {
      this.ticketDataLoading = false;
      this.ticketDetails = data.result[0].ticket_site_status;
    })
  }

}
