<h2 class="section-title no-margin">Assigned Tasks</h2>


<div class="assigned-task-inner-wrapper">
  <div class="loading-wrapper" *ngIf="ticketDataLoading">
    <ngx-skeleton-loader count="4" appearance="line"></ngx-skeleton-loader>
  </div>
  <mat-accordion *ngIf="!ticketDataLoading">
    <ng-container *ngIf="taskList?.length > 0">
      <mat-expansion-panel *ngFor="let task of taskList" (opened)="onAccordionOpened(task)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>{{task.task}}</div>
            <div class="header-icons"><i class="material-icons px-2 green" *ngIf="task.task_submitted">check_circle</i>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="loading-wrapper" *ngIf="dataLoading">
          <div class="loader">
            <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
          </div>
          <div class="loader">
            <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
          </div>
          <div class="loader">
            <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
          </div>
        </div>
        <ng-container *ngIf="!dataLoading">
          <div class="assigned-task-question-wrapper" *ngFor="let question of selectedTaskData.task_questions_list">
            <div class="question-wrapper">
              <div class="question-title"><span class="question">{{question.question}}</span> <span
                  *ngIf="question.task_radio" class="question-radio"><mat-icon>check_circle_outline</mat-icon>Yes</span>
              </div>
              <div class="question-content">
                <span class="upload-time" *ngIf="question.upload_time !== ''"><span class="question-label">Upload
                    Time:</span> {{question.upload_time | date}}</span>
                <div class="site-images">
                  <img src="{{question.task_images}}">
                </div>
              </div>
            </div>
            <!-- <div class="question-wrapper">
            </div> -->
          </div>
        </ng-container>
      </mat-expansion-panel>
    </ng-container></mat-accordion>
</div>

<h2 class="section-title no-margin">Alarm ({{allaramCountLength}})</h2>
<div class="loading-wrapper" *ngIf="ticketDataLoading">
  <ngx-skeleton-loader count="4" appearance="line"></ngx-skeleton-loader>
</div>
<div class="allaram-tasks-wrapper" *ngIf="!ticketDataLoading">
  <ng-container *ngFor="let task of taskList">
    <div class="allaram-list-block" *ngIf="task.task_limit_exceed">
      <div class="task-details">
        <span class="allaram-task-name">{{task.task}}</span>
        <span class="request-type">SLA Exceeded </span>
      </div>
      <div class="allaram-icon">
        <span class="" *ngIf="task.task_limit_exceed"><mat-icon class="red">notification_important</mat-icon></span>
      </div>
    </div>
  </ng-container>
</div>