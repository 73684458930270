import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CallWindowComponent } from 'src/app/call-window/call-window.component';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['./tickets-list.component.scss'],
})
export class TicketsListComponent {
  @Input() user_data: any = {};
  @Input() ticket_data: any = {};
  @Input() ticket_details: any = {};
  @Input() selected_ticket: any = null;
  @Output() ticket: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public userService: UserServiceService
  ) {}
  ngOnInit() {}
  backToList() {
    // this.router.navigate(['']);
    window.history.back();
  }
  chatNow() {
    this.router.navigate(
      this.ticket_details?.id
        ? ['/engineer', this.user_data.id, 'ticket', this.ticket_details.id]
        : ['/engineer', this.user_data.id],
      {
        queryParams: { chatWindow: 1 },
      }
    );
  }
  openCallWindow(receiver_id: number, notify: boolean = true) {
    this.userService.user_call_joining = receiver_id;
    const dialogRef = this.dialog.open(CallWindowComponent, {
      data: { notify: notify, receiver_id },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.userService.user_call_joining = null;
      console.log(`Dialog result: ${result}`);
    });
  }
  onTicketClick(ticket: any) {
    console.log('clicked on the ticket', ticket);
    this.ticket.emit(ticket.ticket_id);
    this.router.navigate([
      '/engineer',
      this.user_data.user_id,
      'ticket',
      ticket.ticket_id,
    ]);
  }
  onCheckBoxChange(e: any = null) {
    e?.stopPropagation();
    return false;
  }
}
