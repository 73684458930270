<!-- <div class="header-wrapper">
    <div class="logo">
        <img src="../assets/logo.svg">
    </div>
    <div class="header-actions">
        <span class="create-user-btn">  <a mat-raised-button >Create User</a></span>
    </div>
</div>
<div class="main-wrapper">
    <div class="left-sidebar-wrapper">
        <app-sidebar-layout></app-sidebar-layout>
    </div> -->
<!-- <div class="right-side-content">
        <h1>tickets</h1>
    </div> -->
<!-- </div> -->

<div class="comp-body cust-tabs cust-dialog">
  <div class="head">
    <h2>Tickets</h2>
    <button class="ms-auto mat-mdc-raised-button" mat-button color="primary" (click)="openDialog()">
      Create New Ticket
    </button>
  </div>
  <div class="tabs-container" *ngIf="!mobileView">
    <!-- <mat-tab-group mat-stretch-tabs="false">
            <nav mat-tab-nav-bar [tabPanel]="tabPanel">
                <a mat-tab-link disabled>Disabled Link</a>
                <a mat-tab-link disabled>Disabled Link</a>
              </nav>
              <mat-tab-nav-panel #tabPanel>
                <mat-tab label="First"> tickets </mat-tab>
                <mat-tab label="Second"> Content 2 </mat-tab>
              </mat-tab-nav-panel>
            </mat-tab-group> -->

    <!--     <mat-tab-group mat-stretch-tabs="false">
      <mat-tab label="All"> -->
    <div class="input-sec d-flex my-3">
      <!-- <form class="ms-auto">
            <mat-form-field appearance="fill">
              <mat-label>Today</mat-label>
              <mat-select>
                <mat-option> vdvsv </mat-option>
                <mat-option> Acsvdv </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="ms-3 search">
              <mat-label>Search By Ticket ID / Site Engineer / Location</mat-label>
              <input matInput placeholder="Enter Key" />
            </mat-form-field>
          </form> -->
    </div>
    <div class="ticket-table-wrapper mat-table-wrapper">

      <div class="mat-elevation-z8">
        <mat-form-field class="full-width">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
        <table mat-table [dataSource]="tickets" matSort>

          <ng-container matColumnDef="ticket_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket ID </th>
            <td mat-cell *matCellDef="let row"> {{row.ticket_name}} </td>
          </ng-container>

          <ng-container matColumnDef="site_engineer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Site Engineer </th>
            <td mat-cell *matCellDef="let row"> {{row.site_engineer}} </td>
          </ng-container>

          <ng-container matColumnDef="operator">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Operator </th>
            <td mat-cell *matCellDef="let row"> {{row.operator}} </td>
          </ng-container>

          <ng-container matColumnDef="site">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Site ID </th>
            <td mat-cell *matCellDef="let row"> {{row.site}} </td>
          </ng-container>
          <ng-container matColumnDef="site_location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Site Location </th>
            <td mat-cell *matCellDef="let row"> {{row.site_location}} </td>
          </ng-container>
          <ng-container matColumnDef="assigned_task">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Assigned Task </th>
            <td mat-cell *matCellDef="let row">
              <ul class="table-list">
                <li *ngFor="let task of row.assigned_task">{{task}} </li>
              </ul>
            </td>
          </ng-container>
          <ng-container matColumnDef="task_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Task Status </th>
            <td mat-cell *matCellDef="let row"> {{row.ticket_status}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">

            <th mat-header-cell *matHeaderCellDef mat-sort-header class="min-height-table-head"> </th>
            <td mat-cell *matCellDef="let row">

              <div class="actions-list">
                <button mat-icon-button (click)="editTicket(row)" matTooltip="Edit Ticket"
                  [disabled]="row.ticket_status !== 'Assigned'"><mat-icon
                    class="align-middle pointer">edit</mat-icon></button>
                <button mat-icon-button (click)="showTicket(row)" matTooltip="View Ticket"><mat-icon
                    class="align-middle pointer">visibility</mat-icon></button>
                <button mat-icon-button (click)="deleteTicket(row)" matTooltip="Delete Ticket"><mat-icon
                    class="align-middle red pointer">delete</mat-icon></button>
              </div>
            </td>
          </ng-container>

          <!--   <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="min-height-table-head"></mat-header-cell>
                <mat-cell *matCellDef="let row;" class="align-items-right min-height-table-cell">
                
                </mat-cell>
              </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">
              <app-table-skelton-loader *ngIf="dataLoading" [headers]="displayedColumns"></app-table-skelton-loader>
              <div class="no-data-found" *ngIf="!dataLoading">There Is No Data To Display</div>
            </td>
          </tr>
        </table>

        <mat-paginator [length]="tickets?.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" #ticketsPaginator
          aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
    <!--  -->
    <!--      </mat-tab> -->
    <!--   <mat-tab label="In Progress"> Content 2 </mat-tab>
      <mat-tab label="Completed"> Content 3 </mat-tab>
      <mat-tab label="Cancelled"> Content 3 </mat-tab> -->
    <!--  </mat-tab-group> -->
  </div>

</div>
<div class="mobile-gird-view" *ngIf="mobileView">
  <div class="grid-wrapper">
    <div class="grid-block" *ngFor="let ticket of tickets.data">
      <div class="grid-title-wrapper">
        <span class="gird-title-label">Ticket ID</span>
        <span class="gird-title-value">{{ticket.ticket_name}}</span>
      </div>
      <div class="grid-body">
        <div class="grid-body-title-wrapper">
          <span class="gird-body-title-label">Site Engineer</span>
          <span class="grid-seperator">:</span>
          <span class="gird-body-title-value">{{ticket.site_engineer}}</span>
        </div>
        <div class="grid-body-title-wrapper">
          <span class="gird-body-title-label">Operator</span>
          <span class="grid-seperator">:</span>
          <span class="gird-body-title-value">{{ticket.operator}}</span>
        </div>
        <div class="grid-body-title-wrapper">
          <span class="gird-body-title-label">Site ID</span>
          <span class="grid-seperator">:</span>
          <span class="gird-body-title-value">{{ticket.site}}</span>
        </div>
        <div class="grid-body-title-wrapper">
          <span class="gird-body-title-label">Site Location</span>
          <span class="grid-seperator">:</span>
          <span class="gird-body-title-value">{{ticket.site_location}}</span>
        </div>
        <div class="grid-body-list-title-wrapper">
          <span class="gird-body-list-title-label">Assigned Tasks</span>
          <ul class="table-list">
            <li *ngFor="let task of ticket.assigned_task">{{task}} </li>
          </ul>
        </div>
      </div>
      <div class="grid-footer">
        <span class="gird-body-footer-value">{{ticket.ticket_status}}</span>
        <div class="actions-list">
          <button mat-icon-button (click)="editTicket(ticket)" matTooltip="Edit Ticket"
            [disabled]="ticket.ticket_status !== 'Assigned'"><mat-icon
              class="align-middle pointer">edit</mat-icon></button>
          <button mat-icon-button (click)="showTicket(ticket)" matTooltip="View Ticket"><mat-icon
              class="align-middle pointer">visibility</mat-icon></button>
          <button mat-icon-button (click)="deleteTicket(ticket)" matTooltip="Delete Ticket"><mat-icon
              class="align-middle red pointer">delete</mat-icon></button>
        </div>
      </div>
    </div>
  </div>
</div>