import { Component } from '@angular/core';
import { SocketService } from './services/socket.service';
import { UserServiceService } from './services/user-service.service';
import { Router } from '@angular/router';
// adding subscription for call and chat message notifications
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'exagen-solutions';
  constructor(
    private socket: SocketService,
    private userService: UserServiceService,
    private router: Router
  ) {
    if (!userService.userDetails) {
      userService.setLoginUserDetails();
    }
  }
  loginConnect: any = null;
  socketConnect: any = null;
  ngAfterViewInit(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    if (this.userService.userDetails) {
      this.socket.connect(this.userService.userDetails.id);
    }
    this.loginConnect = this.userService.loginMessage$.subscribe((input) => {
      const status = input.login;
      if (status) {
        if (!this.userService.userDetails) {
          this.userService.setLoginUserDetails();
        }

        this.socket.connect(this.userService.userDetails.id);
      } else {
        this.socket.closeSocket();
        this.clearSubscriptions();
      }
    });
    this.socketConnect = this.socket.onSocketConnect$.subscribe(
      ({ connected }) => {
        if (connected) this.subsribeForSockets(this.userService.userDetails);
        else this.clearSubscriptions();
      }
    );
  }

  notificationList: any[] = [
    // {
    //   type: 'video-call',
    //   user_id: 98,
    //   data: {
    //     user_id: 1,
    //   },
    // },
    // {
    //   type: 'notifications-message',
    //   user_id: 98,
    //   data: {
    //     id: 125,
    //     receiver_ids: [1],
    //     message: 'yes, i am about to accept it',
    //     created_at: '2023-05-20T06:33:07.000Z',
    //     sender_id: 98,
    //   },
    // },
  ];

  getUserDetails = (i: number): any => {
    const item = this.notificationList[i];
    if (!item) {
      return null;
    }
    this.userService.getUserData(item.user_id).subscribe((resp: any) => {
      if (resp.result && resp.result.length > 0) {
        this.notificationList[i].user = resp.result[0];
        this.notificationList[i].user.name =
          resp.result[0].first_name +
          (resp.result[0].last_name ? ' ' + resp.result[0].last_name : '');
      }
      console.log('user data got ', resp, this.notificationList);
    });
  };
  removeNotification = (i: number) => {
    this.notificationList.splice(i, 1);
    this.checkHasVideoCall();
  };
  openChat = (i: number) => {
    const item = this.notificationList[i];
    this.router.navigate(['/engineer', item.user_id], {
      queryParams: { chatWindow: 1 },
    });
    this.removeNotification(i);
  };
  openCall = (i: number) => {
    const item = this.notificationList[i];
    this.router.navigate(['/engineer', item.user_id], {
      queryParams: {
        callWindow: Math.random() * 100 + 1,
        at: new Date().valueOf() / 1000,
      },
    });
    this.removeNotification(i);
    this.checkHasVideoCall();
  };
  declineCall = (i: number) => {
    const item = this.notificationList[i];
    this.removeNotification(i);
    this.checkHasVideoCall();
  };

  socketObj: any = null;
  subscribe = (data: any) => {
    // handle new data from gc
    const findIndex = this.notificationList.findIndex(
      (notification) =>
        notification.user_id === data.user_id && data.type === notification.type
    );
    let autoRemoveIndex: number = -1;
    console.log('the data find check', findIndex, data.type, data.user_id);
    if (findIndex >= 0) {
      if (data.type !== 'video-call') {
        this.notificationList[findIndex].count =
          (this.notificationList[findIndex].count || 0) + 1;
      }
      if (
        data.type === 'video-call' &&
        this.userService.user_call_joining !== null &&
        data.user_id === this.userService.user_call_joining
      ) {
        this.notificationList[findIndex].data.message =
          'User joined in the call';
        data.hideActions = true;
        autoRemoveIndex = findIndex;
      }
    } else {
      if (
        data.type === 'video-call' &&
        this.userService.user_call_joining !== null &&
        data.user_id === this.userService.user_call_joining
      ) {
        data.data.message = 'User joined in the call';
        data.hideActions = true;
        autoRemoveIndex = this.notificationList.length;
      }
      this.notificationList.push(data);
      this.getUserDetails(this.notificationList.length - 1);
    }
    this.checkHasVideoCall();
    if (autoRemoveIndex >= 0) {
      setTimeout(() => {
        if (
          this.notificationList.length > autoRemoveIndex &&
          data.user_id === this.notificationList[autoRemoveIndex].user_id &&
          data.type === this.notificationList[autoRemoveIndex].type
        ) {
          this.notificationList.splice(data);
          this.checkHasVideoCall();
        }
      }, 5000);
    }
  };

  subsribeForSockets = (user_data: any) => {
    this.socketObj = this.socket.get_socket();
    this.socket.joinRoom('notifications-' + user_data.id);
    this.socketObj.on(
      'notifications-' + user_data.id + 'Message',
      this.subscribe
    );
  };

  clearSubscriptions = () => {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.userService.userDetails) {
      this.socket.leaveRoom('notifications-' + this.userService.userDetails.id);
      this.socketObj?.removeListener(
        'notifications-' + this.userService.userDetails.id + 'Message',
        this.subscribe
      );
    }
    this.stopTune();
  };
  ngOnDestroy(): void {
    this.clearSubscriptions();
    if (this.loginConnect) this.loginConnect.unsubscribe();
    if (this.socketConnect) this.socketConnect.unsubscribe();
  }
  checkHasVideoCall = () => {
    let has_audio = false;
    const findIndex = this.notificationList.findIndex(
      (n) => n.type === 'video-call'
    );
    if (findIndex >= 0 && this.playStatus === false) {
      this.playTune();
    }
    if (findIndex === -1 && this.playStatus === true) {
      this.stopTune();
    }
  };
  playStatus: boolean = false;
  playTune(): any {
    if (this.userService.user_call_joining !== null) {
      return false;
    }
    let player = <HTMLAudioElement>document.getElementById('incoming-audio');
    player.load();
    player.play();
    this.playStatus = true;
  }
  stopTune(): void {
    let player = <HTMLAudioElement>document.getElementById('incoming-audio');
    player.pause();
    this.playStatus = false;
  }
}
