import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-skelton-loader',
  templateUrl: './table-skelton-loader.component.html',
  styleUrls: ['./table-skelton-loader.component.scss']
})
export class TableSkeltonLoaderComponent {
 @Input() headers: any;
}
