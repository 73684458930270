<div *ngIf="!login_checked">Loading...I</div>
<div class="header-wrapper" *ngIf="login_checked">
    <div class="logo-wrapper">
        
        <div class="logo">
            <img src="../assets/_logo.svg">
        </div>
    </div>
    <div class="header-actions">
        <div class="header-profile" [matMenuTriggerFor]="menu">
            <div class="user-image">
                <img src="{{userDetails && userDetails.image ?? '/assets/icons/maps/engineer.png'}}" />
            </div>
            <div class="login-user-details">
                <span class="login-user-name">{{userDetails?.first_name}} {{userDetails?.last_name}}</span>
                <span class="login-user-designation">[ {{userDetails?.user_type === '1' ? 'Local Admin' : 'Master Admin'}} ]</span>
            </div>
        </div>
        <div class="settings-wrapper" [matMenuTriggerFor]="menu">
            <mat-icon>arrow_drop_down</mat-icon>
        </div>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="createUser()" *ngIf="userDetails?.user_type === '0'"><mat-icon>person_add</mat-icon> Admin Profile</button>
            <button mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon> Logout</button>
        </mat-menu>
    </div>
</div>
<div class="main-wrapper" [class.sidebar-collapsed]="!activeSidebar" *ngIf="login_checked">
    <div class="left-sidebar-wrapper" [class.show-mobile-sidebar]="displayMobileSidebar">
        <app-sidebar-layout (updateSidebar)="sidebarStatus($event)"></app-sidebar-layout>
    </div>
    <div class="right-side-content">
        <router-outlet></router-outlet>
    </div>
</div>