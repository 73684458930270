import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogPopupComponent } from '../../dialog-popup/dialog-popup.component';
import { UserServiceService } from 'src/app/services/user-service.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-masters',
  templateUrl: './masters.component.html',
  styleUrls: ['./masters.component.scss']
})
export class MastersComponent {
  @Output() mastersBack = new EventEmitter();
  displayedColumns: string[] = ['employee_id', 'employee_name','role', 'email', 'phone', 'contract_start_date', 'contract_end_date', 'actions'];
  mastersData: any = new MatTableDataSource<any>();

  public dataLoading: boolean = false;
 

  operatoresColumnsHeaders: string[] = ['operator_name', 'actions'];
  tasksColumnsHeaders: string[] = ['task', 'task_code', 'actions'];
  siteHeaders: string[] = ['operator_id', 'site_code', 'site_location', 'site_longitude', 'site_latitude', 'actions']


  @ViewChild('masterPaginator') masterPaginator: MatPaginator | undefined;
  @ViewChild('operatorPaginator') operatorPaginator: MatPaginator | undefined;
  @ViewChild('tasksPaginator') tasksPaginator: MatPaginator | undefined;
  @ViewChild('sitesPaginator') sitesPaginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatSort) operatorSort: MatSort | undefined;
  @ViewChild(MatSort) taskSort: MatSort | undefined;
  @ViewChild(MatSort) siteSort: MatSort | undefined;

  masters: any;
  operators: any  = new MatTableDataSource<any>();
  tasks: any = new MatTableDataSource<any>();
  sites: any = new MatTableDataSource<any>();
  constructor(public dialog: MatDialog, public userService: UserServiceService, private router: Router) { }
  ngOnInit() {
    this.getMastersData();

  }
  ngAfterViewInit() {
    this.mastersData.paginator = this.masterPaginator;
    this.operators.paginator = this.operatorPaginator;
    this.tasks.paginator = this.tasksPaginator;
    this.sites.paginator = this.sitesPaginator;

    this.mastersData.sort = this.sort;
    this.operators.operatorSort = this.sort;
    this.tasks.taskSort = this.sort;
    this.sites.siteSort = this.sort;
  }
  openDialog() {
    this.dialog.open(DialogPopupComponent, {
      data: {
        form: 'master'
      }
    }).afterClosed().subscribe((res) => {
      setTimeout(() => {
        this.getMastersData();
      }, 3000)
    });;
  }

  openOperatorDialog() {
    this.dialog.open(DialogPopupComponent, {
      data: {
        form: 'operators'
      }
    }).afterClosed().subscribe((res) => {
      setTimeout(() => {
        this.getOperatorsData();
      }, 1000)
    });;
  }

  openTaskDialog() {
    this.dialog.open(DialogPopupComponent, {
      data: {
        form: 'tasks'
      }
    }).afterClosed().subscribe((res) => {

      setTimeout(() => {
        this.getTaskData();
      }, 1000)
    });;
  }

  openSiteDialog() {
    this.dialog.open(DialogPopupComponent, {
      data: {
        form: 'sites',
        optData: this.operators.data,
      }
    }).afterClosed().subscribe((res) => {
      setTimeout(() => {
        this.getSiteData();
      }, 1000)

    });;
  }

  getMastersData() {
    this.dataLoading = true;
    this.userService.getMaster().subscribe((data: any) => {
      this.dataLoading = false;
      this.masters = data['result']['users_list'];  
      this.mastersData.data = [...this.masters];
    })
  }

  getOperatorsData() {
    this.userService.getOperators().subscribe((data: any) => {
      this.operators.data = data['result'];
      console.log(this.operators)

    })
  }

  getTaskData() {
    this.userService.getTask().subscribe((data: any) => {
      this.tasks.data = data['result'];
    })
  }

  getSiteData() {
    this.userService.getSite().subscribe((data: any) => {
      this.sites.data = data['result'];
    })
  }

  backToOptions() {
    this.router.navigate(['/admin'])
  }

  eiditGC(gcData: any) {
    this.userService.getGCDetails(gcData.user_id).subscribe((data) => {
      this.dialog.open(DialogPopupComponent, {
        data: {
          form: 'master',
          masterData: data.result
        }

      }).afterClosed().subscribe((res) => {
        setTimeout(() => {
          this.getMastersData();
        }, 3000)
      });
    })
  }

  applyGCFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.mastersData.filter = filterValue.trim().toLowerCase();
  }

  applyOperatorsFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.operators.filter = filterValue.trim().toLowerCase();
  }

  applyTasksFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tasks.filter = filterValue.trim().toLowerCase();
  }

  applySitesFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sites.filter = filterValue.trim().toLowerCase();
  }
}
