<div class="information-management-wrapper">
    <div class="information-block">
        <div class="information-title">
            Construction Management
        </div>
        <div class="information-list-wrapper">
            <ul>
                <li>Scheduling</li>
                <li>Budget tracking</li>
                <li>Material procurement </li>
                <li>Tower and foundation analyses</li>
                <li>Zoning and permitting approvals</li>
                <li>Daily project forecasting and reporting</li>
            </ul>

        </div>
    </div>
    <div class="information-block">
        <div class="information-title">
            Civil Construction
        </div>
        <div class="information-list-wrapper">
            <ul>
                <li>HVAC</li>
                <li>Access roads </li>
                <li> Protective shelters</li>
                <li> Tower foundations</li>
                <li> Grounding systems </li>
                <li> Landscaping and fencing</li>
                <li> UPS backup power systems</li>
                <li> Civil engineering testing (foundation, slump, break, soil resistivity, etc.) </li>
                <li> Compliant AC/DC electrical equipment installations (OSHA, NFPA 70E & NEC)</li>

            </ul>

        </div>
    </div>
    <div class="information-block">
        <div class="information-title">
            Tower Construction
        </div>
        <div class="information-list-wrapper">
            <ul>
                <li>Tower erection
                    <ul>
                        <li>5G towers and custom structures</li>
                        <li>Monopole, self-support, and guyed towers</li>
                        <li>Pre-existing structures (buildings, rooftops, water tanks, silos)</li>
                        <li>Stealth tower solutions (flagpole, pine tree, church steeple, bell tower)</li>

                    </ul>
                </li>
                <li> Ice bridge/cableway</li>
                <li> Line and antenna installation</li>
                <li> FAA compliant obstruction lighting systems for any tower</li>

            </ul>

        </div>
    </div>
    <div class="information-block">
        <div class="information-title">
            Tower Testing
        </div>
        <div class="information-list-wrapper">
            <ul>
                <li> Sweep testing</li>
                <li> RF signal readings</li>
                <li> Passive Intermodulation (PIM) testing</li>
                <li> Common public radio interface (CPRI) testing</li>
                <li> Fiber Optical Time Domain Reflectometer (OTDR) testing</li>


            </ul>

        </div>
    </div>
    <div class="information-block">
        <div class="information-title">
            Closeout Documentation
        </div>
        <div class="information-list-wrapper">
            <ul>
                <li>Height verification</li>
                <li>Electrical certificates</li>
                <li>Installation photographs</li>
                <li>Building permit closeouts</li>
                <li>Sweep, fiber OTDR, and PIM test results</li>

            </ul>

        </div>
    </div>
    <div class="information-services-block">
        <div class="information-block">
            <div class="information-title">
                Tower Maintenance Services
            </div>
            <div class="information-flex-wrapper">
                <div class="information-flex-block">
                    <div class="information-cion">
                        <img src="../../assets/info-1.jpg">
                    </div>

                    <div class="information-list-wrapper">
                        <ul>
                            <li> Landscaping</li>
                            <li> Access road repair</li>
                            <li> Storm damage repairs</li>
                            <li>Plumb and tension</li>
                            <li> Cathodic protection</li>


                        </ul>

                    </div>
                </div>


                <div class="information-flex-block">
                    <div class="information-cion">
                        <img src="../../assets/info-2.jpg">
                    </div>

                    <div class="information-list-wrapper">
                        <ul>
                            <li> Troubleshooting</li>
                            <li> Anchor replacement</li>
                            <li> UPS battery replacement</li>
                            <li> Paint, primer, and rust removal</li>
                            <li> Grounding system replacement</li>

                        </ul>

                    </div>
                </div>
                <div class="information-flex-block">
                    <div class="information-cion">
                        <img src="../../assets/info-3.jpg">
                    </div>

                    <div class="information-list-wrapper">
                        <ul>
                            <li>Equipment shelter maintenance</li>
                            <li> Antenna and line maintenance</li>
                            <li> Repair of critical inspection discoveries</li>
                            <li> Backup generator and HVAC maintenance</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="information-block">
        <div class="information-title">
            Cell Tower Inspection
        </div>
        <div class="information-list-wrapper">
            <ul>
                <li> Grounding inspections</li>
                <li>Dig-to-block inspections</li>
                <li>Post-modification inspections</li>
                <li>TIA-compliant annual inspections</li>
                <li>Guy tension measurements</li>
                <li>Hi-resolution photos of any uncovered issues </li>
                <li>Customized closeout documentation</li>


            </ul>

        </div>
    </div>
    <div class="information-block">
        <div class="information-title">
            UPS Battery Maintenance & Testing 
        </div>
        <div class="information-list-wrapper">
            <ul>
                <li>Annual inspection and testing</li>
                <li>Ensure regulatory compliance</li>
                <li>Identification of weak cells and faulty connections</li>
                <li>Reduce battery replacement rate and frequency</li>
                <li>Impedance and load bank testing</li>
                
            </ul>

        </div>
    </div>

    <div class="information-block">
        <div class="information-title">
            EARTH RESISTANCE DIFF TERRIAN VALUES
        </div>
        <div class="information-list-wrapper">
            <div class="information-images-wrapper">
                <div class="info-image"><img src="../../assets/information/image1.png"></div>
                <div class="info-image"><img src="../../assets/information/image2.png"></div>
                <div class="info-image"><img src="../../assets/information/image3.png"></div>
                <div class="info-image"><img src="../../assets/information/image4.png"></div>
                <div class="info-image"><img src="../../assets/information/image5.png"></div>
                <div class="info-image"><img src="../../assets/information/image6.png"></div>
                <div class="info-image"><img src="../../assets/information/image7.png"></div>
            </div>
        </div>
    </div>
    <div class="information-block">
        <div class="information-title">
            Measurements at different distances R1 to R9 from 10 to 90 % of the distance SH
        </div>
        <div class="information-list-wrapper">
            <div class="information-images-wrapper">
                <div class="info-image"><img src="../../assets/information/image8.png"></div>
                <div class="info-image"><img src="../../assets/information/image9.png"></div>
                
            </div>
        </div>
    </div>
    <div class="information-block">
        <div class="information-title">
            GROUND RING EARTH RESISTANCE CHECK 
        </div>
        <div class="information-list-wrapper">
            <div class="information-images-wrapper">
                <div class="info-image"><img src="../../assets/information/image10.png"></div>
                <div class="info-image"><img src="../../assets/information/image11.png"></div>
                <div class="info-image"><img src="../../assets/information/image12.png"></div>
            </div>
        </div>
    </div>
    <div class="information-block">
        <div class="information-title">
            Coupling measurement across multiple earthing points
        </div>
        <div class="information-list-wrapper">
            <div class="information-images-wrapper">
                <div class="info-image"><img src="../../assets/information/image13.png"></div>
            </div>
        </div>
    </div>
</div>