import { environment } from "src/environments/environment";
export const ENDPOINT = {
    GENERAL_SERVICE: `${ environment.apiBaseURL }/api`,
};

export const CONTROLLER = {
    DATA: 'data',
    ADMIN: 'admin',
    APP: 'app'
};

export const API = {
    MEMBER: {
        ASSIGEDNTASK: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.ADMIN }/ticket/assign/task/`,
    }
   
};
