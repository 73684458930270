<div class="section-wrapper">
  <div class="page-section-title">
    History
  </div>
  <div class="date-picker-wrapper">
    <mat-form-field appearance="outline">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" [max]="today" (dateChange)="onDateChange('change', $event)">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="multi-year" [startAt]="todayDate"></mat-datepicker>
    </mat-form-field>
  </div>
</div>

<div class="history-main-wrapper">


  <div class="days-list-wrapper horizontal-scroll">
    <mat-tab-group (selectedTabChange)="getHistoryData($event)" #tabs>
      <mat-tab [label]="date.dayOfWeek" *ngFor="let date of daysArray"
        [disabled]="currentYear == date.year && currentMonth == date.month && date.date - 2  >= todayDay">
        <ng-template mat-tab-label>
          <div class="month">{{(this.selectedYear+'-' + selectedMonth + '-01') | date: 'MMM'}}</div>
          <span class="day">{{date.dayOfWeek}}</span>
          <span class="date">{{date.date}}</span>
        </ng-template>
        <div class="history-wrapper">

          <div class="history-list">
            <div class="history-list-block">
              <!--  <mat-accordion>
                <mat-expansion-panel *ngFor="let history of historyData">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{history.gc_name}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <p>I'm visible because I am open</p>
                </mat-expansion-panel>
               
              </mat-accordion> -->
              <div class="mat-elevation-z8">
                <table mat-table [dataSource]="historyData" multiTemplateDataRows>

                  <ng-container matColumnDef="gc_id">
                    <th mat-header-cell *matHeaderCellDef> GC ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.gc_id}} </td>
                  </ng-container>

                  <ng-container matColumnDef="gc_name">
                    <th mat-header-cell *matHeaderCellDef> GC Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.gc_name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="ticket_complete_count">
                    <th mat-header-cell *matHeaderCellDef> Completed Tickets </th>
                    <td mat-cell *matCellDef="let element"> {{element.ticket_complete_count}} </td>
                  </ng-container>

                  <ng-container matColumnDef="sla_exceed">
                    <th mat-header-cell *matHeaderCellDef> Tasks SLA Exceeded</th>
                    <td mat-cell *matCellDef="let element"> {{element.sla_exceed}} </td>
                  </ng-container>
                  <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let element">
                      <button mat-icon-button aria-label="expand row"
                        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                  <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                      <div class="example-element-detail history-details"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="history-tickets-list">
                          {{element.description}}
                          <span class="table-accordion-title"> Tickets List </span>
                          <mat-list role="list">
                            <mat-list-item role="listitem" [class.active]="ticket.isActive"
                              (click)="getTicketDetails(ticket, element.ticket_list, element.gc_user_id)"
                              *ngFor="let ticket of element.ticket_list">{{ticket.ticket_name}}</mat-list-item>
                          </mat-list>
                        </div>
                        <div class="history-tickets-details">
                          <div class="ticket-details-inner-wrapper">
                            <div class="ticket-status-details-wrapper">
                              <span class="table-accordion-title"> Ticket Details </span>
                              <div class="table-row" *ngIf="ticketDataLoading">
                                <ngx-skeleton-loader count="1" appearance="line"
                                  [theme]="{ 'border-radius': '0',width: '100%', height: '40px' }"></ngx-skeleton-loader>
                                <ngx-skeleton-loader count="1" appearance="line"
                                  [theme]="{ 'border-radius': '0',width: '100%', height: '40px' }"></ngx-skeleton-loader>
                                <ngx-skeleton-loader count="1" appearance="line"
                                  [theme]="{ 'border-radius': '0',width: '100%', height: '40px' }"></ngx-skeleton-loader>
                                <ngx-skeleton-loader count="1" appearance="line"
                                  [theme]="{ 'border-radius': '0',width: '100%', height: '40px' }"></ngx-skeleton-loader>
                                <ngx-skeleton-loader count="1" appearance="line"
                                  [theme]="{ 'border-radius': '0',width: '100%', height: '40px' }"></ngx-skeleton-loader>
                              </div>
                              <ng-container *ngIf="!ticketDataLoading">
                                <div class="ticket-detail-block" *ngFor="let ticket of ticketDetails">
                                  <span class="ticket-status-icon"><img src="{{ticket.image}}"></span>
                                  <span class="ticket-status">{{ticket.status}}</span>
                                  <span class="ticket-status-time">{{ticket.time}}</span>
                                </div>
                              </ng-container>
                            </div>
                            <div class="gc-directions-wrapper">
                              <span class="table-accordion-title"> Location Timeline </span>
                              <div class="history-map-wrapper" *ngIf="showMap">
                                <agm-map [latitude]="lat" [longitude]="lng">
                                  <agm-direction [origin]="origin" [destination]="destination" [waypoints]="waypoints"
                                    [renderOptions]="renderOptions" [markerOptions]="markerOptions">
                               <!--      <agm-marker-cluster maxZoom="13"
                                      imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                                      <agm-marker *ngFor="let marker of markers" [latitude]="marker.lat"
                                        [longitude]="marker.lng" [iconAnchorY]="10" [iconHeight]="20" [iconWidth]="20">
                                        [iconUrl]="marker.iconUrl"
                                        >
                                        <agm-info-window>
                                          <h2>{{marker.title}}</h2>
                                          <a routerLink="{{ marker.url }}">Details</a>
                                        </agm-info-window>
                                      </agm-marker>

                                    </agm-marker-cluster> -->
                                  </agm-direction>
                                </agm-map>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="accordion-header"
                    [class.example-expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="5">
                      <app-table-skelton-loader *ngIf="dataLoading"
                        [headers]="displayedColumns"></app-table-skelton-loader>
                      <div class="no-data-found" *ngIf="!dataLoading">There Is No Data To Display</div>
                    </td>
                  </tr>
                </table>


              </div>

            </div>
            <!--  -->
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

  </div>

</div>