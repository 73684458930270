<div class="root">
  <div class="modal-title">
    <h2 mat-dialog-title>Video/Audio Call</h2>
    <mat-icon mat-dialog-close class="close-icon">close</mat-icon>
  </div>
  <mat-dialog-content class="call-window">
  <iframe
    class="call-window-frame"
    [src]="callURL()"
    title="description"
    allow="camera;microphone"
  ></iframe>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
      </mat-dialog-actions> -->
</div>
