<!-- <div class="header-wrapper">
    <div class="logo">
        <img src="../assets/logo.svg">
    </div>
    <div class="header-actions">
        <span class="create-user-btn">  <a mat-raised-button >Create User</a></span>
    </div>
</div>
<div class="main-wrapper">
    <div class="left-sidebar-wrapper">
        <app-sidebar-layout></app-sidebar-layout>
    </div>
    <div class="right-side-content">
        <router-outlet></router-outlet>
    </div>
</div> -->

<router-outlet></router-outlet>
<div class="notification-container">
  <ng-container *ngFor="let item of notificationList; let i = index">
    <ng-container
      [ngTemplateOutlet]="
        item.type == 'video-call' ? call_temp : chat_message_temp
      "
      [ngTemplateOutletContext]="{ item: item, index: i }"
    >
    </ng-container>
  </ng-container>
</div>

<audio src="../assets/tones/incoming-call.mp3" loop id="incoming-audio"></audio>

<ng-template #call_temp let-item="item" let-index="index">
  <div class="notification-item call-container content-center">
    <div class="close-icon" (click)="removeNotification(index)">
      <i class="fa fa-times"></i>
    </div>
    <div class="pulse"><i class="fas fa-phone fa-2x"></i></div>
    <div class="text-container">
      <div class="user-name">{{ item.user?.name || "---" }}</div>
      <div class="message" *ngIf="item.data?.message">{{ item.data.message }}</div>
      <div class="action-container" *ngIf="!item.hideActions">
        <button class="action-button success" (click)="openCall(index)">
          Accept
        </button>
        <button
          class="action-button danger margin-left"
          (click)="declineCall(index)"
        >
          Reject
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #chat_message_temp let-item="item" let-index="index">
  <div class="notification-item chat-container content-center">
    <div class="close-icon" (click)="removeNotification(index)">
      <i class="fa fa-times"></i>
    </div>
    <div class="notification-icon">
      <i class="fa fa-comment fa-2x icon"></i>
      <span class="counter-badge" *ngIf="item.count">+{{ item.count }}</span>
    </div>
    <div class="text-container">
      <div class="user-name">{{ item.user?.name || "---" }}</div>
      <div class="message">{{ item.data.message }}</div>
      <div class="action-container">
        <button
          class="action-button default margin-left"
          (click)="openChat(index)"
        >
          View
        </button>
      </div>
    </div>
  </div>
</ng-template>
