<div color="primary" class="header header-fixed">
  <div class="responsive-menu"><mat-icon>menu</mat-icon></div>
  <a routerLink="/"
    ><img src="assets/logo.svg" alt="Logo" class="logo-image"
  /></a>
  <!-- <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
  >
    <mat-icon>menu</mat-icon>
  </button> -->
  <!-- <span> My App</span> -->
  <span class="example-spacer"></span>
  <!-- <button
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
    img
  >
    <mat-icon>favorite</mat-icon>
  </button>
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with share icon"
  >
    <mat-icon>share</mat-icon>
  </button> -->
  <button mat-button>
    <mat-icon>account_circle</mat-icon> John
  </button>
</div>
