<div class="comp-body cust-tabs cust-dialog">
    <div class="head">
        <div class="innter-comp-title"><mat-icon (click)="backToOptions()"
                class="back-btn">keyboard_arrow_left</mat-icon>
            <h2>Field Engineers</h2>
        </div>
        <div class="input-sec d-flex my-3">
            <form class="ms-auto">
                <!--  <mat-form-field class="ms-3 search">
                        <mat-label>Search By Operators</mat-label>
                        <input matInput placeholder="Enter Key">
                    </mat-form-field> -->
            </form>
            <button class="ms-3 mat-mdc-raised-button" mat-button color="primary" (click)="openDialog()">+ Add
                FE</button>
        </div>
    </div>
    <div class="master-table-wrapper mat-table-wrapper">
        <div class="mat-elevation-z8">
            <mat-form-field class="full-width">
                <mat-label>Search FE</mat-label>
                <input matInput (keyup)="applyGCFilter($event)"  #input>
            </mat-form-field>
            <table mat-table [dataSource]="mastersData" matSort>

                <ng-container matColumnDef="employee_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> FE ID </th>
                    <td mat-cell *matCellDef="let row"> {{row.employee_id}} </td>
                </ng-container>

                <ng-container matColumnDef="employee_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> FE Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.employee_name}}</td>
                </ng-container>

                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
                    <td mat-cell *matCellDef="let row"> {{row.role}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Address </th>
                    <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact Number </th>
                    <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
                </ng-container>
                <ng-container matColumnDef="contract_start_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract Start Date </th>
                    <td mat-cell *matCellDef="let row"> {{(row?.contract_start_date | date)?? '-'}} </td>
                </ng-container>
                <ng-container matColumnDef="contract_end_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract End Date </th>
                    <td mat-cell *matCellDef="let row"> {{(row?.contract_end_date | date )?? '-'}} </td>
                </ng-container>
                <!--      <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                <td mat-cell *matCellDef="let row"> <mat-slide-toggle></mat-slide-toggle> </td>
                            </ng-container> -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="min-height-table-head"></mat-header-cell>
                    <mat-cell *matCellDef="let row;" class="align-items-right min-height-table-cell">
                        <!--  <button mat-icon-button (click)="eiditGC(row)">
                                        <mat-icon class="align-middle" >edit</mat-icon>
                                    </button> -->
                        <!--   <button mat-icon-button> 
                                        <mat-icon class="align-middle">visibility</mat-icon>
                                    </button> -->
                        <!--  <button mat-icon-button> 
                                        <mat-icon class="align-middle red">delete</mat-icon>
                                    </button> -->
                    </mat-cell>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="7">
                       
                        <app-table-skelton-loader *ngIf="dataLoading"
                            [headers]="displayedColumns"></app-table-skelton-loader>
                        <div class="no-data-found" *ngIf="!dataLoading">There Is No Data To Display</div>
                    </td>
                </tr>
            </table>

            <mat-paginator [length]="mastersData?.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
                #masterPaginator aria-label="Select page">
            </mat-paginator>
        </div>
    </div>


</div>

<!-- <div class="header-wrapper">
    <div class="logo">
        <img src="../assets/logo.svg">
    </div>
    <div class="header-actions">
        <span class="create-user-btn">  <a mat-raised-button >Create User</a></span>
    </div>
</div>
<div class="main-wrapper">
    <div class="left-sidebar-wrapper">
        <app-sidebar-layout></app-sidebar-layout>
    </div> 
    <div class="right-side-content">
        <h1>masters</h1>
    </div>
</div> -->