import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserServiceService } from '../services/user-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  public activeSidebar: any = true;
  public userDetails: any;
  public login_checked: boolean = true;
  public displayMobileSidebar: boolean = false;
  constructor(
    private router: Router,
    private userService: UserServiceService
  ) {}
  ngAfterViewInit() {
    this.activeSidebar = window.innerWidth >= 768;
    console.log("active", this.activeSidebar);
    localStorage.setItem('isLoggedIn','true');
    setTimeout((): any => {
      console.log('Hiiii', localStorage.getItem('isLoggedIn'));
      if (
        !localStorage.getItem('isLoggedIn') ||
        localStorage.getItem('isLoggedIn') !== 'true'
      ) {
        console.log('redirecting the user');
        this.router.navigate(['/login']);
        this.logout();
        return false;
      }
      this.login_checked = true;
      const userData = localStorage.getItem('userDetails');
      this.userDetails = userData !== null ? JSON.parse(userData) : null;
/*       this.userService.checkUserLogin(this.userDetails.id).subscribe((data) => {
        if(data.result.is_login) {
          this.logout();
        } else {
          console.log("i am in",data)
        }
      }) */
      console.log(this.userDetails);
    }, 100);
  }
  createUser() {
    this.router.navigate(['/admin']);
  }
  showSidebar() {
    this.displayMobileSidebar = !this.displayMobileSidebar;
  }
  logout(clearHost?: any) {
    const userData = localStorage.getItem('userDetails');
    let userDetails = userData !== null ? JSON.parse(userData) : null;
    if (clearHost) {
      this.userService.onUserLogout();
      localStorage.removeItem('userDetails');
      this.router.navigate(['/login']);
      localStorage.removeItem('isLoggedIn');
    }
    this.userService
      .logOut({ user_id: userDetails?.id })
      .subscribe((data: any) => {
        this.userService.onUserLogout();
        localStorage.removeItem('userDetails');
        localStorage.removeItem('isLoggedIn');
        this.router.navigate(['/login']);
        console.log({ data });
      });
    // localStorage.removeItem('userDetails');
    // this.router.navigate(['/login']);
    // localStorage.removeItem('isLoggedIn');

    /* */
  }

  sidebarStatus(event: any) {
    console.log({ event });
    this.activeSidebar = event;
  }

  @HostListener("window:resize", [])
  onWindowScroll() {
      this.activeSidebar = window.innerWidth <= 768;
  }
}
