import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import config from 'src/config';
import { UserServiceService } from '../services/user-service.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-call-window',
  templateUrl: './call-window.component.html',
  styleUrls: ['./call-window.component.scss'],
})
export class CallWindowComponent {
  constructor(
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
    private userService: UserServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (!userService.userDetails) {
      userService.setLoginUserDetails();
    }
    this.uuid = userService?.userDetails?.id || null;
    this.name =
      userService.userDetails.first_name +
        (userService.userDetails.last_name
          ? ' ' + userService.userDetails.last_name
          : '') || null;
    console.log('data got for setting notify', data);
    if (data && data.notify === false) {
      this.notify = 0;
    }
    if (data && data.receiver_id) {
      this.receiver_id = data.receiver_id;
    }
  }
  // uuid = Math.floor(Math.random() * 500) + 10;
  uuid: number | null = null;
  name: string | null = null;
  notify: number = 1;
  receiver_id: number = 0;
  callURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.callURL +
        '?notify=' +
        this.notify +
        '&uid=' +
        this.uuid +
        '&receiver_id=' +
        this.receiver_id +
        '&name=' +
        (this.name ? encodeURI(this.name) : '')
    );
  }
  ngAfterViewInit() {
    this.ref.detach();
  }
}
