import { Component, Inject } from '@angular/core';
import { MaterialModule } from '../material.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
  standalone: true,
  imports: [MaterialModule]
})
export class WarningDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   
  ) { }

  coonfirm() {

  }
}
