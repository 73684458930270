import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardComponent as SecondDashboardComponent } from './dashboard/dashboard.component';
import { HeaderLayoutComponent } from './layout/header-layout/header-layout.component';
import { HeaderComponent } from './layout/common/header/header.component';
import { MaterialModule } from './material.module';
import { SidebarLayoutComponent } from './layout/sidebar-layout/sidebar-layout.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserServiceService } from './services/user-service.service';
import { SocketService } from './services/socket.service';
import { CommonService } from './services/common.service';
import { TicketsComponent } from './tickets/tickets.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { MastersComponent } from './admin/masters/masters.component';
import { DialogPopupComponent } from './dialog-popup/dialog-popup.component';
import { BrowserModule } from '@angular/platform-browser';
import { HistoryComponent } from './history/history.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CallWindowComponent } from './call-window/call-window.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { WindowService } from './common/window/window.service';
import { OveralSummaryComponent } from './overal-summary/overal-summary.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { NgChartsModule } from 'ng2-charts';
import { SharedModule } from './shared/shared.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AgmDirectionModule } from 'agm-direction';
import { AgmCoreModule } from '@agm/core';
import { SafePipe } from './pipes/sanitizer.pipe';
import { AnalyticsComponent } from './analytics/analytics.component';

@NgModule({
  declarations: [
    AppComponent,
    SecondDashboardComponent,
    HeaderLayoutComponent,
    HeaderComponent,
    SidebarLayoutComponent,
    TicketsComponent,
    HealthCheckComponent,
    MastersComponent,
    DialogPopupComponent,
    HistoryComponent,
    CallWindowComponent,
    AnalyticsComponent,
    SafePipe
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    HttpClientModule,
    CarouselModule,
    BrowserModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    AgmCoreModule.forRoot({ // @agm/core
      apiKey: 'AIzaSyBY8M_ewufQ5RPAOdOfLeaSn7w9vkXcyjI',
    }),
    AgmDirectionModule  
  ],
  exports: [
  ],
  providers: [
    UserServiceService,
    CommonService,
    SocketService,
    MatDatepickerModule,
    MatSnackBarModule,
    WindowService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
