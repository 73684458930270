import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public isChatWindow = new Subject();
  constructor( private http: HttpClient) { }

  setChatWindow(value: boolean) {
    this.isChatWindow.next(value)
  }

  getChatWindow() :Observable<any> {
    return this.isChatWindow;
  }

  getAddress(latitude: number, longitude: number): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBY8M_ewufQ5RPAOdOfLeaSn7w9vkXcyjI`;
    return this.http.get(url);
  }
}
