import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  public adminDashboardChips = [
    {
      title: 'Local Admin',
      icon: 'admin',
      url: '/admin/local-admin'
    },
    {
      title: 'FE',
      icon: 'gc',
      url: '/admin/fe'
    },
    {
      title: 'Operators',
      icon: 'gear',
      url: '/admin/operators'
    },
    {
      title: 'Sites',
      icon: 'sites',
      url: '/admin/sites'
    }
  ]

  public groupTwoChips = [
    {
      title: 'Tasks',
      icon: 'tasks',
      url: '/admin/tasks'
    },
    {
      title: 'Questions',
      icon: 'questions',
      url: '/admin/questions',
      disable: false,
    },
    {
      title: 'Ticket Status',
      icon: 'status',
      url: '/admin/status',
      disable: false,
    }
  ]
  constructor(private router: Router) { }
  onChipClick(chip: any) {

    this.router.navigate([chip.url]);

  }
}
