import { Component } from '@angular/core';
import { UserServiceService } from '../services/user-service.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent {
  public iframeData: any;
  public iframeURL: any
  constructor(private userService: UserServiceService, protected _sanitizer: DomSanitizer){}
  ngOnInit() {
    this.userService.getIframeURL().subscribe((data: any) => {
      this.iframeData = data;
      this.iframeURL = this._sanitizer.bypassSecurityTrustUrl(data.iframe)
    }) 
  }

}
