import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
})
export class SidebarLayoutComponent {
  @Output() updateSidebar = new EventEmitter();
  public menuItems: any = [
     {
      title: 'Admin Dashboard',
      icon: 'dashboard',
      isActive: false,
      route: '',
      hasAccess: true,
    },
    {
      title: 'FE Overview',
      icon: 'analytics',
      isActive: true,
      route: 'fe',
      hasAccess: true,
    },
   
     {
       title: 'Health Checks',
       icon: 'favorite',
       isActive: true,
       route: 'health-check',
       hasAccess: true,
     }, 
    {
      title: 'Tickets',
      icon: 'receipt',
      isActive: true,
      route: 'tickets',
      hasAccess: true,
    },
    {
      title: 'History',
      icon: 'av_timer',
      isActive: true,
      route: 'history',
      hasAccess: true,
    },
    {
      title: 'Analytics',
      icon: 'timeline',
      isActive: true,
      route: 'analytics',
      hasAccess: true,
    },
    {
      title: 'Information',
      icon: 'information',
      isActive: true,
      route: 'information',
      hasAccess: true,
    },
  ];
  public activeSideBar: boolean = true;
  public hideTitles: boolean = false;
  public currrentLink: any;
  public userDetails: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserServiceService
  ) {}
  ngOnInit() {
    let currentLink: any = window.location.href.split('/');
    currentLink = currentLink[currentLink.length - 1];
    this.currrentLink = currentLink;
    let mentuItem = this.menuItems.filter((data: any) => {
      if (data.route === this.currrentLink) {
        return data;
      }
    })[0];
    this.selectMenuItem(mentuItem);
    const locUserData = localStorage.getItem('userDetails');
    this.userDetails = locUserData ? JSON.parse(locUserData) : null;
    this.menuItems.map((data: any) => {
      if (data.title === 'Analytics') {
        data.hasAccess = this.userDetails?.user_type === '0';
      }
    });
    this.activeSideBar = window.innerWidth >= 800;
    console.log("sidebar", this.activeSideBar, window.innerWidth );
  }
  selectMenuItem(menuItem: any) {
    this.menuItems.forEach((data: any) => (data.isActive = false));
    if (menuItem) {
      menuItem.isActive = true;
      this.router.navigate([menuItem.route]);
    }
  }

  toggleSideBar() {
    this.activeSideBar = !this.activeSideBar;
    if (!this.hideTitles) {
      setTimeout(() => {
        this.hideTitles = true;
      }, 100);
    } else {
      setTimeout(() => {
        this.hideTitles = false;
      }, 200);
    }
    this.updateSidebar.emit(this.activeSideBar);
  }
  logout() {
    this.userService.onUserLogout();
    this.router.navigate(['/login']);
  }
}
