import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import config from 'src/config';
import { environment } from 'src/environments/environment';
const base_url = environment.apiBaseURL;
const socker_base_url = environment.socketBaseURL;
@Injectable({
  providedIn: 'root',
})
export class UserServiceService {
  siteStatusList: any[] = [];
  userDetails: any = null;
  user_call_joining: number | null = null;
  // Observable string sources
  private loginMessagSource = new Subject<any>();
  private sendData: any;

  // Observable string streams
  loginMessage$ = this.loginMessagSource.asObservable();

  // Service message commands
  onUserLogin() {
    this.setLoginUserDetails()
    this.loginMessagSource.next({ login: 1 });
  }

  onUserLogout() {
    this.userDetails = null;
    this.loginMessagSource.next({ login: 0 });
  }

  constructor(private http: HttpClient) { }

  public createUser(updateUser: any): Observable<any> {
    return this.http.post(base_url + 'admin/user/signup/', updateUser);
  }

  public getActiveEmployees({ }: any): Observable<any> {
    return this.http.post(base_url + 'admin/active/employee/', {});
  }

  public getActiveUsersMap(params: any = {}): Observable<any> {
    return this.http.get(socker_base_url + '/active-users', {
      params: params.user_id ? { user_id: params.user_id } : {},
    });
  }

  public createEmployee(employeeData: any): Observable<any> {
    return this.http.post(base_url + 'admin/user/signup/', employeeData);
  }

  public getTicketDatails(params: any): Observable<any> {
    return this.http.get(base_url + 'admin/ticket/details/', {
      params: params,
    });
  }

  public getTicketData({ }: any): Observable<any> {
    let userData: any = localStorage.getItem('userDetails');
    const userDetails = JSON.parse(userData);

    const sendData = {
      user_type: userDetails?.user_type,
      user_id: userDetails?.id,
    };
    return this.http.get(base_url + 'admin/ticket/create/data/', {
      params: sendData,
    });
  }

  public getSiteData(id: any): Observable<any> {
    return this.http.get(base_url + 'admin/site/list?operator_id=' + id);
  }
  public getSiteStatusList(): Observable<any> | null {
    if (this.siteStatusList.length > 0) {
      return null;
    }
    return this.http.get(base_url + 'admin/site/status/config/list/').pipe(
      tap((resp: any) => {
        console.log('the status not came', resp);
        if (resp.result) this.siteStatusList = resp.result || [];
        return resp;
      })
    );
  }

  public createTicket(ticketData: any): Observable<any> {
    return this.http.post(base_url + 'admin/ticket/create/', ticketData);
  }

  public getTickets(): Observable<any> {
    const locUserData = localStorage.getItem('userDetails');
    const userDetails = locUserData ? JSON.parse(locUserData) : null;
    const sendData = {
      user_type: userDetails?.user_type,
      user_id: userDetails?.id,
      project: userDetails?.project_id,
      ticket_status: '',
    };
    return this.http.post(base_url + 'admin/ticket/list/', sendData);
  }

  public getTicketList(id: number): Observable<any> {
    return this.http.get(base_url + 'admin/dashboard/ticket/list/', {
      params: { user_id: id },
    });
  }

  public getMaster(): Observable<any> {
    return this.http.get(base_url + 'admin/employee/list/');
  }

  public getOperators(): Observable<any> {
    return this.http.get(base_url + 'data/operator/list');
  }

  public createOperator(operatorData: any): Observable<any> {
    return this.http.post(base_url + 'data/operator/create/', operatorData);
  }

  public createTask(taskData: any): Observable<any> {
    return this.http.post(base_url + 'admin/task/create/', taskData);
  }

  public getTask(): Observable<any> {
    return this.http.post(base_url + 'admin/task/list/', {});
  }

  public createSite(siteData: any): Observable<any> {
    return this.http.post(base_url + 'data/site/create/', siteData);
  }

  public getSite(): Observable<any> {
    return this.http.get(base_url + 'data/all/site/list');
  }

  public getAssignedTaskDetails(ticketId: any): Observable<any> {
    return this.http.post(base_url + 'admin/ticket/assign/task/', {
      ticket_id: ticketId,
    });
  }

  public getProjectList(): Observable<any> {
    return this.http.get(base_url + 'data/operator/list');
  }

  public login(loginData: any): Observable<any> {
    console.log({ loginData });
    loginData.login_from = "web";
    return this.http.post(base_url + 'admin/user/login/', loginData);
  }

  public getGCDetails(gcID: string): Observable<any> {
    return this.http.get(base_url + `admin/user/edit/?user_id=${gcID}`);
  }

  public getLocalAdmins(): Observable<any> {
    return this.http.get(base_url + `admin/local/admin/list/`);
  }

  public getAllQuestions(): Observable<any> {
    return this.http.post(base_url + `admin/task/all/questions/list/`, {});
  }

  public setLoginUserDetails(): any {
    this.userDetails =
      localStorage.getItem('userDetails') !== null
        ? JSON.parse(localStorage.getItem('userDetails') as string)
        : null;
  }

  public getUserData(id: number): any {
    return this.http.post(base_url + `admin/user/data/id/`, { user_id: id });
  }
  public logOut(logOutData: any): Observable<any> {
    return this.http.post(base_url + 'admin/user/logout/', logOutData);
  }

  public getSummary(): Observable<any> {
    let userData: any = localStorage.getItem('userDetails');
    const userDetails = JSON.parse(userData);

    const sendData = {
      user_type: userDetails?.user_type,
      user_id: userDetails?.id,
    };
    return this.http.get(base_url + `admin/engineer/overall/summary/`, {
      params: sendData,
    });
  }

  public createQuestion(questionObject: any): Observable<any> {
    return this.http.post(
      base_url + `admin/task/questions/create/`,
      questionObject
    );
  }

  public editOperator(operatorId: any) {
    const sendObject = {
      id: operatorId,
    };
    return this.http.post(base_url + 'data/operator/edit/', sendObject);
  }

  public updateOperator(operatorData: any) {
    return this.http.post(base_url + 'data/operator/update/ ', operatorData);
  }
  public createStatus(statusObject: any): Observable<any> {
    return this.http.post(
      base_url + `admin/site/status/config/create/`,
      statusObject
    );
  }

  public getStatusList(): Observable<any> {
    return this.http.get(base_url + `admin/site/status/config/list/`);
  }

  public getStatusData(statusId: string): Observable<any> {
    return this.http.get(base_url + `admin/site/status/config/edit/?id=${statusId}`)
  }
  public updateStatusData(statusObj: any): Observable<any> {
    return this.http.post(base_url + "admin/site/status/config/update/", statusObj);
  }

  public getMonthlyStatus(statusID: string, year: string, month: string): Observable<any> {
    return this.http.post(base_url + "admin/dashboard/user/status/day/", {
      'status_id': statusID, year, month
    })
  }

  public getIframeURL(): Observable<any> {
    return this.http.post(base_url + "admin/report/iframe/", { 'env': environment.enVersion })
  }

/*   public checkUserLogin(userId: any): Observable<any>  {
    return this.http.get(base_url + `admin/check/user/logout?user_id=${userId}`);
  } */
}
