<!-- Screen 1 - Health Check -->
<div class="comp-body cust-tabs cust-dialog" *ngIf="!loader && !fetchReport">
    <div class="head d-flex">
        <h2>Health Check</h2> <button class="ms-auto mat-mdc-raised-button" mat-button color="primary" (click)="openDialog()">New Health
            Check</button>
    </div>
    <table class="app-theme-table mat-mdc-table mdc-data-table__table cdk-table mat-elevation-z8">
        <thead>
            <tr class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">#</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Checked At</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Operators</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Site ID</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Reqest Type</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Alarms</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Report</th>
            </tr>
        </thead>
        <tbody>
            <tr class="mat-mdc-row mdc-data-table__row cdk-row">
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">1234</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Mnt, 00,
                    0000 00:00 PM</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Verizon
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    CVL0810978</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Fiber
                    Loss</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded" style="font-variation-settings: 'FILL' 1;">
                        visibility
                    </span>
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded">
                        arrow_circle_down
                    </span>
                </td>
            </tr>
            <tr class="mat-mdc-row mdc-data-table__row cdk-row">
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">1234</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Mnt, 00,
                    0000 00:00 PM</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Verizon
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    CVL0810978</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Fiber
                    Loss</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded" style="font-variation-settings: 'FILL' 1;">
                        visibility
                    </span>
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded">
                        arrow_circle_down
                    </span>
                </td>
            </tr>
            <tr class="mat-mdc-row mdc-data-table__row cdk-row">
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">1234</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Mnt, 00,
                    0000 00:00 PM</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Verizon
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    CVL0810978</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Fiber
                    Loss</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded" style="font-variation-settings: 'FILL' 1;">
                        visibility
                    </span>
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded">
                        arrow_circle_down
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Screen 2 - Fetching Screen -->
<div class="comp-body fetching-screen" *ngIf="loader && !fetchReport">
    <div class="inner-content">
        <h4 class="my-0">Fetch Pre Check Report</h4>
        <span class="material-symbols-rounded">
            cached <span class="progress">10%</span>
        </span>
        <p>Fetching...</p>
        <button mat-stroked-button color="warn" (click)="fetchingReport()">Cancel Fetching</button>
    </div>
</div>

<!-- Screen 3 - Health Check Status-->
<div class="comp-body health-check-status" *ngIf="fetchReport">
    <div class="head d-flex">
        <span class="material-symbols-rounded me-3" (click)="back()" style="cursor : pointer">
            keyboard_backspace
        </span>
        <h2>Health Check Status - Alarms</h2> <button class="ms-auto" color="warn" mat-stroked-button> <span
                class="material-symbols-rounded">arrow_circle_down</span> Download Precheck Report</button>
    </div>
    <div class="boxes mb-3 row">
        <div class="col-3">
            <div class="box">
                <span class="icon">
                    <span class="material-symbols-rounded">
                        tag
                    </span>
                </span>
                <span class="text me-3">
                    Health Check ID
                    <strong>HC009</strong>
                </span>
            </div>
        </div>
        <div class="col-3">
            <div class="box">

                <span class="icon me-3">
                    <span class="material-symbols-rounded">
                        mediation
                    </span>
                </span>
                <span class="text">
                    Health Check ID
                    <strong>HC009</strong>
                </span>
            </div>
        </div>
        <div class="col-3">
            <div class="box">
                <span class="icon me-3">

                    <span class="material-symbols-rounded">
                        repeat
                    </span>
                </span>
                <span class="text">
                    Health Check ID
                    <strong>HC009</strong>
                </span>
            </div>
        </div>
        <div class="col-3">
            <div class="box">
                <span class="icon me-3">

                    <span class="material-symbols-rounded">
                        text_snippet
                    </span>

                </span>
                <span class="text">
                    Health Check ID
                    <strong>HC009</strong>
                </span>
            </div>
        </div>
    </div>
    <table class="app-theme-table mat-mdc-table mdc-data-table__table cdk-table mat-elevation-z8">
        <thead>
            <tr class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">#</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Checked At</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Operators</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Site ID</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Reqest Type</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Alarms</th>
                <th class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">Report</th>
            </tr>
        </thead>
        <tbody>
            <tr class="mat-mdc-row mdc-data-table__row cdk-row">
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">1234</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Mnt, 00,
                    0000 00:00 PM</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Verizon
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    CVL0810978</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Fiber
                    Loss</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded" style="font-variation-settings: 'FILL' 1;">
                        visibility
                    </span>
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded">
                        arrow_circle_down
                    </span>
                </td>
            </tr>
            <tr class="mat-mdc-row mdc-data-table__row cdk-row">
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">1234</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Mnt, 00,
                    0000 00:00 PM</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Verizon
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    CVL0810978</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Fiber
                    Loss</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded" style="font-variation-settings: 'FILL' 1;">
                        visibility
                    </span>
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded">
                        arrow_circle_down
                    </span>
                </td>
            </tr>
            <tr class="mat-mdc-row mdc-data-table__row cdk-row">
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">1234</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Mnt, 00,
                    0000 00:00 PM</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Verizon
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    CVL0810978</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">Fiber
                    Loss</td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded" style="font-variation-settings: 'FILL' 1;">
                        visibility
                    </span>
                </td>
                <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-position mat-column-position">
                    <span class="material-symbols-rounded">
                        arrow_circle_down
                    </span>
                </td>
            </tr>
        </tbody>
    </table>

</div>



<!-- ====================================OLD============================================== -->

<!-- <div class="header-wrapper">
    <div class="logo">
        <img src="../assets/logo.svg">
    </div>
    <div class="header-actions">
        <span class="create-user-btn">  <a mat-raised-button >Create User</a></span>
    </div>
</div> -->
<!-- <div class="main-wrapper">
    <div class="left-sidebar-wrapper">
        <app-sidebar-layout></app-sidebar-layout>
    </div> -->
<!-- <div class="right-side-content">
       <h1>health check</h1>
    </div> -->
<!-- </div> -->