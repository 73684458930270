import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { io } from 'socket.io-client';
import config from 'src/config';
import { environment } from 'src/environments/environment';
const url = environment.socketBaseURL;
@Injectable({
  providedIn: 'root',
})
export class SocketService {
  // Observable string sources
  private socketConnecctSource = new Subject<any>();

  // Observable string streams
  onSocketConnect$ = this.socketConnecctSource.asObservable();

  // Service message commands
  onConnect() {
    this.socketConnecctSource.next({ connected: 1 });
  }

  onDisconnect() {
    this.socketConnecctSource.next({ connected: 0 });
  }

  socket: any = null;
  constructor() {}
  public connect(id: number) {
    this.socket = io(url, {
      query: { user_id: id },
    });
    this.socket.on('connect', () => {
      console.log('on connection checking');
      this.isConnected = true;
      setTimeout(() => {
        this.onConnect();
      }, 100);
    });
  }
  isConnected: boolean = false;

  public sendMessage(message: any) {
    console.log('sendMessage: ', message);
    this.socket.emit('message', message);
  }
  public joinRoom(room: any) {
    console.log('joining the room', room, 'is connected', this.isConnected);
    this.socket.emit('join', room);
  }
  public leaveRoom(room: any) {
    this.socket && this.socket.emit('leave', room);
  }
  public get_socket() {
    return this.socket;
  }
  public closeSocket() {
    this.onDisconnect();
    this.socket.disconnect();
  }
}
