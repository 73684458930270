<div class="skelton-wrapper">

    <div class="table-body-skelton">
        <div class="table-row">
            <ngx-skeleton-loader *ngFor="let head of headers" count="1" appearance="line"
                [theme]="{ 'border-radius': '0',width: '100%', height: '20px' }"></ngx-skeleton-loader>

        </div>
        <div class="table-row">
            <ngx-skeleton-loader *ngFor="let head of headers" count="1" appearance="line"
                [theme]="{ 'border-radius': '0',width: '100%', height: '20px' }"></ngx-skeleton-loader>
        </div>
        <div class="table-row">
            <ngx-skeleton-loader *ngFor="let head of headers" count="1" appearance="line"
                [theme]="{ 'border-radius': '0',width: '100%', height: '20px' }"></ngx-skeleton-loader>
        </div>
        <div class="table-row">
            <ngx-skeleton-loader *ngFor="let head of headers" count="1" appearance="line"
                [theme]="{ 'border-radius': '0',width: '100%', height: '20px' }"></ngx-skeleton-loader>
        </div>
        <div class="table-row">
            <ngx-skeleton-loader *ngFor="let head of headers" count="1" appearance="line"
                [theme]="{ 'border-radius': '0',width: '100%', height: '20px' }"></ngx-skeleton-loader>
        </div>
        <div class="table-row">
            <ngx-skeleton-loader *ngFor="let head of headers" count="1" appearance="line"
                [theme]="{ 'border-radius': '0',width: '100%', height: '20px' }"></ngx-skeleton-loader>
        </div>
    </div>
</div>