import { Component, Input, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { map, takeWhile, timer } from 'rxjs';
import { SocketService } from 'src/app/services/socket.service';
import { distance } from 'src/app/utils';
declare var google: any;

function buildImageIcon(
  image: any,
  title: any,
  update: boolean = false,
  element: any = null,
  size: 'medium' | 'small' = 'medium'
) {
  const content = document.createElement('div');
  content.classList.add('property-image');
  const defaults: any = {
    icon: '../../../assets/icons/maps/engineer.png',
  };
  content.innerHTML = `
  <div class="icon">
      <img src="${image}" class="${
    size === 'small' ? 'small' : ''
  }" alt="${title}" onerror="this.src='${
    defaults.icon
  }';"/>
  </div>`;
  if (update) {
    const child_nodes = element.childNodes[0].childNodes;
    for (let i = 0; i < child_nodes.length; i++) {
      child_nodes[i].remove();
    }
    element.childNodes[0].append(content);
  }
  return content;
}
const siteStatusToImage: any = {
  5: '/assets/map-images/site-plain.png',
  6: '/assets/map-images/survey.png',
  7: '/assets/map-images/placement.png',
  8: '/assets/map-images/meter.png',
};
function buildImageIconCheckIn(
  status: any,
  title: string,
  update: boolean = false,
  element: any = null
) {
  console.log('showing the new images of user');
  const content = document.createElement('div');
  content.classList.add('property-image-user');
  content.innerHTML = `
  <div class="icon">
      <img title="${title}" src="${
    siteStatusToImage[status > 8 ? 8 : status]
  }" alt="Site status" />
  </div>`;
  if (update) {
    const child_nodes = element.childNodes[0].childNodes;
    for (let i = 0; i < child_nodes.length; i++) {
      child_nodes[i].remove();
    }
    element.childNodes[0].append(content);
  }
  return content;
}

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasicInfoComponent {
  constructor(private socket: SocketService) {}
  @Input() user_data: any = null;
  @Input() task_list: any = null;
  @Input() ticket_data: any = null;

  @Input() ticket_status_list: any = null;

  setIntitialData = () => {
    this.convertData(this.ticket_status_list);
  };
  active_ticket_site_status: number | null = null;
  // convertData = (new_data: any[]) => {
  //   if (!new_data) {
  //     return null;
  //   }
  //   this.status_list[1].active = -1;
  //   this.status_list[2].active = -1;
  //   this.status_list[3].active = -1;
  //   this.status_list[4].active = -1;
  //   if (this.task_list && Array.isArray(this.task_list)) {
  //     const findSurvey = this.task_list.findIndex(
  //       (item) => item.task === 'Site Survey'
  //     );
  //     if (findSurvey >= 0) {
  //       this.status_list[4].active = 0;
  //     }

  //     const findFixing = this.task_list.findIndex(
  //       (item) => item.task === 'Signage Placement/Fixing'
  //     );
  //     if (findFixing >= 0) {
  //       this.status_list[3].active = 0;
  //     }

  //     const findMeterLogging = this.task_list.findIndex(
  //       (item) => item.task === 'Meter Reader Logging'
  //     );
  //     if (findMeterLogging >= 0) {
  //       this.status_list[2].active = 0;
  //     }
  //     const findFiberInspection = this.task_list.findIndex(
  //       (item) => item.task === 'Fiber Inspection'
  //     );
  //     if (findFiberInspection >= 0) {
  //       this.status_list[1].active = 0;
  //     }
  //   }
  //   let last_completed_status_index = -1;
  //   this.status_list.forEach((status_data, index) => {
  //     if (status_data.active === -1) {
  //       return false;
  //     }
  //     let found = false;
  //     for (let i = 0; i < new_data.length; i++) {
  //       const item = new_data[i];
  //       if (item.status === status_data.header) {
  //         found = true;
  //         if (item.time || item.sla) {
  //           status_data.time = item.time;
  //           status_data.sla = item.sla;
  //           status_data.active = 2;
  //           if (last_completed_status_index === -1) {
  //             last_completed_status_index = index;
  //           }
  //         }
  //         break;
  //       }
  //     }
  //     if (!found) {
  //       status_data.time = '';
  //       status_data.sla = '';
  //       status_data.active = 0;
  //     }
  //     return true;
  //   });
  //   console.log('data at the final', last_completed_status_index);
  //   let set_active = false;
  //   if (last_completed_status_index >= 0) {
  //     for (let i = this.status_list.length - 1; i >= 0; i--) {
  //       const status_data = this.status_list[i];
  //       if (status_data.active === -1) {
  //         continue;
  //       }
  //       const index = i;
  //       if (!set_active && index < last_completed_status_index) {
  //         set_active = true;
  //         status_data.active = 1;
  //         this.active_ticket_site_status = status_data.status;
  //       }
  //       if (index >= last_completed_status_index && status_data.active === 0) {
  //         status_data.active = 2;
  //       }
  //     }
  //   } else {
  //     this.status_list[7].active = 1;
  //     this.active_ticket_site_status = 1;
  //   }
  //   if (!set_active && this.active_ticket_site_status === null) {
  //     this.active_ticket_site_status = 1;
  //   } else {
  //     for (let i = 0; i < this.status_list.length; i++) {
  //       const status_item = this.status_list[i];
  //       if (status_item.active === -1) {
  //         continue;
  //       }
  //       if (status_item.active === 1) {
  //         this.active_ticket_site_status = status_item.status;
  //         break;
  //       }
  //     }
  //   }
  //   if (
  //     this.active_ticket_site_status !== null &&
  //     [1, 2, 4].indexOf(this.active_ticket_site_status)
  //   ) {
  //     if(!this.socket_connected)this.subsribeForLiveLocation();
  //   } else {
  //     this.unSubscribe();
  //   }
  //   this.calculateAndDisplayRoute();
  //   console.log(
  //     'data after setting new values',
  //     this.status_list,
  //     this.active_ticket_site_status
  //   );
  //   return true;
  // };
  // status_list: any[] = [
  //   {
  //     fa_icon: 'fa-solid fa-circle-check',
  //     header: 'Completed',
  //     sla: null,
  //     time: null,
  //     active: 0,
  //     status: 99999999,
  //   },
  //   {
  //     fa_icon: 'fa-solid fa-gauge-simple-high',
  //     header: 'Fiber Inspection',
  //     text: null,
  //     active: -1,
  //     status: 8,
  //   },
  //   {
  //     fa_icon: 'fa-solid fa-gauge-simple-high',
  //     header: 'Meter Reader Logging',
  //     text: null,
  //     active: -1,
  //     status: 7,
  //   },
  //   {
  //     fa_icon: 'fa-solid fa-screwdriver-wrench',
  //     header: 'Signage Placement/Fixing',
  //     text: null,
  //     active: -1,
  //     status: 6,
  //   },
  //   {
  //     fa_icon: 'fa-solid fa-chess-board',
  //     header: 'Site Survey',
  //     text: null,
  //     active: -1,
  //     status: 5,
  //   },
  //   {
  //     fa_icon: 'fa-solid fa-tower-cell',
  //     header: 'Check In',
  //     text: null,
  //     active: 0,
  //     status: 4,
  //   },
  //   {
  //     fa_icon: 'fa-regular fa-calendar-check',
  //     header: 'Accepted',
  //     text: null,
  //     active: 0,
  //     status: 2,
  //   },
  //   {
  //     fa_icon: 'fa-regular fa-eye',
  //     header: 'Viewed',
  //     text: null,
  //     active: 0,
  //     status: 1,
  //   },
  // ];

  timerStarted: boolean = false;
  timeRemaining$: any = null;
  status_list: any[] = [];
  convertData = (new_data: any[]) => {
    if (this.timeRemaining$) this.timeRemaining$.unsubscribe();
    this.status_list = new_data.map((item, index) => {
      const previousStatusItem =
        index + 1 < new_data.length ? new_data[index + 1] : null;
      if (item.color === '#9d1b95') {
        this.active_ticket_site_status = parseInt(item.status_id) || 0;
        // if (item.start_timer === 'start' && item.sla) {
        //   // starting timer related
        //   const secondsToRun =
        //     parseInt(item.sla.replace(' mins', '').replace(' min', '')) * 60;

        //   if (previousStatusItem) {
        //     const secondsSince = Math.floor(
        //       moment().unix() -
        //         moment(previousStatusItem.time, 'hh:mm A, DD MMM YYYY').unix()
        //     );

        //     if (secondsSince < secondsToRun) {
        //       const Remaing = secondsToRun - secondsSince;
        //       const activeIndex = index;
        //       this.timerStarted = true;
        //       this.timeRemaining$ = timer(0, 1000).pipe(
        //         map((n) => (Remaing - n) * 1000),
        //         takeWhile((n) => {
        //           if (n >= 0) {
        //             this.status_list[activeIndex].start_timer = 'stop';
        //             this.status_list[activeIndex].exceeded = true;
        //             item.start_timer = 'stop';
        //             this.timerStarted = false;
        //             this.timeRemaining$.unsubscribe();
        //           }

        //           return n >= 0;
        //         })
        //       );
        //     }
        //   }
        // }
      }
      // let exceeded = false;
      // if (previousStatusItem && item.sla && previousStatusItem.time) {
      //   const secondsToRun =
      //     parseInt(item.sla.replace(' mins', '').replace(' min', '')) * 60;
      //   const secondsSince = Math.floor(
      //     moment().unix() -
      //       moment(previousStatusItem.time, 'hh:mm A, DD MMM YYYY').unix()
      //   );
      //   if (secondsSince >= secondsToRun) {
      //     exceeded = true;
      //     item.start_timer = 'stop';
      //   }
      // }

      return {
        image: item.image,
        header: item.status,
        sla: item.sla,
        time: item.time,
        color: item.color,
        start_timer: item.start_timer,
        status_id: parseInt(item.status_id),
        // exceeded: exceeded,
        task_exceed_time: item.task_exceed_time,
        task_exceed_flag: item.task_exceed_flag,
        task_exceed_socket: item.task_exceed_socket,
      };
    });

    if (
      this.active_ticket_site_status !== null &&
      [1, 2, 4].indexOf(this.active_ticket_site_status) >= 0
    ) {
      this.activateSocketConnection();
    } else {
      this.unSubscribe();
    }
    if (this.ticket_data && this.user_data) {
      this.calculateAndDisplayRoute();
    }
  };

  ngAfterContentInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.initGoogleMaps();
  }
  map: any = null;
  centerLatLng = { lat: 36.0902, lng: -97.4194 };
  directionsService: any = null;
  directionsRenderer: any = null;
  site_marker: any = null;
  initGoogleMaps() {
    let lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      scale: 2,
    };
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: '#c60e55',
        strokeWeight: 0,
        strokeOpacity: 1,
        icons: [
          {
            icon: lineSymbol,
            offset: '10px',
            repeat: '10px',
          },
        ],
      },
    });
    const map_container = document.getElementById('ticket-map');
    if (!map_container) {
      return null;
    }

    this.map = new google.maps.Map(map_container, {
      zoom: 4.4,
      center: this.centerLatLng,
      disableDefaultUI: true,
      mapTypeId: 'roadmap',
      mapId: '4504f8b37365c3d0',
    });

    this.directionsRenderer.setMap(this.map);

    this.calculateAndDisplayRoute();
    return true;
  }

  distanceValue: number | null = null;
  start_lat: number | null = null;
  start_lng: number | null = null;
  distance: string | null = null;
  duration: string | null = null;
  can_get_map: boolean = true;
  directionLoadedCode: string | null = null;
  calculateAndDisplayRoute(force: boolean = false) {
    if (!this.directionsService) {
      return false;
    }
    if (
      this.user_data.latitude === null ||
      this.user_data.latitude === undefined ||
      this.user_data.longitude === null ||
      this.user_data.longitude === undefined ||
      this.ticket_data.site_latitude === null ||
      this.ticket_data.site_latitude === undefined ||
      this.ticket_data.site_longitude === null ||
      this.ticket_data.site_longitude === undefined
    ) {
      this.can_get_map = false;
      return false;
    }
    const code =
      this.user_data.id +
      '-' +
      this.ticket_data.id +
      (this.active_ticket_site_status || 0);
    console.log('the comparison', code, this.directionLoadedCode);
    if (!force && this.directionLoadedCode === code) {
      return false;
    }

    this.directionLoadedCode = code;
    this.can_get_map = true;
    let start = new google.maps.LatLng(
      this.user_data.latitude,
      this.user_data.longitude
    );
    let end = new google.maps.LatLng(
      this.ticket_data.site_latitude,
      this.ticket_data.site_longitude
    );
    console.log(
      'the user status is not loading properly',
      this.active_ticket_site_status
    );
    if (this.active_ticket_site_status && this.active_ticket_site_status > 4) {
      this.directionsRenderer.setMap(null);
      if (this.site_marker) {
        this.site_marker.setMap(null);
        console.log('not woring updating marker at site');
      }
      if (this.enginner_marker) {
        this.enginner_marker.setMap(null);
      }
      this.map.setCenter(end);
      this.map.setZoom(20);
      // this.makeEngineerMarker(this.user_data);
      this.startMarker(this.user_data, end);

      return true;
    }

    let request = {
      origin: start,
      destination: end,
      travelMode: google.maps.DirectionsTravelMode.DRIVING,
    };
    this.site_marker && this.site_marker.setMap(null);
    this.enginner_marker && this.enginner_marker.setMap(null);
    this.directionsService
      .route(request)
      .then((response: any) => {
        if (
          response.status == google.maps.DirectionsStatus.OK &&
          !(
            this.active_ticket_site_status && this.active_ticket_site_status > 4
          )
        ) {
          let leg = response.routes[0].legs[0];
          this.distance = leg.distance.text;
          this.distanceValue =
            distance(
              leg.start_location.lat(),
              leg.start_location.lng(),
              this.user_data.latitude,
              this.user_data.longitude,
              'K'
            ) * 1000 || 200;

          this.duration = leg.duration.text;
          this.directionsRenderer.setMap(this.map);
          console.log('response.routes[0].legs[0].distance.text', leg);
          this.directionsRenderer.setDirections(response);
          this.makeEngineerMarker(this.user_data);
          this.start_lat = leg.start_location.lat();
          this.start_lng = leg.start_location.lng();
          this.startMarker(this.user_data, leg.start_location);
          this.makeSiteMarker(this.ticket_data, leg.end_location);
        } else {
          if (
            !(
              this.active_ticket_site_status &&
              this.active_ticket_site_status > 4
            )
          ) {
            this.can_get_map = false;
          }
        }
      })
      .catch((e: any) => {
        if (
          !(
            this.active_ticket_site_status && this.active_ticket_site_status > 4
          )
        ) {
          this.can_get_map = false;
        }
        console.error(e);
      });
    return true;
  }

  setMapCenter = (latitude: number, longitude: number, zoom: number = 20) => {
    console.log('centering map', zoom);
    this.map.setZoom(zoom);
    this.map.setCenter(new google.maps.LatLng(latitude, longitude));
  };

  siteMarkerPosition: any = null;
  makeSiteMarker(ticket: any, position: any) {
    this.siteMarkerPosition = position;
    if (!this.site_marker) {
      this.site_marker = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        content: buildImageIcon(
          '../../../assets/icons/maps/tower-new.png',
          ticket.site_location
        ),
        position: position,
        title: ticket.site_location,
      });
      const element = this.site_marker.element;
      this.site_marker.addListener('click', (event: any) => {
        this.setMapCenter(this.siteMarkerPosition, 20);
      });
    } else {
      this.site_marker.setMap(this.map);
      this.site_marker.position = position;
      this.site_marker.content = buildImageIcon(
        '../../../assets/icons/maps/tower-new.png',
        ticket.site_location
      );
      this.site_marker.element.title = ticket.site_location;
    }
    this.site_marker.setMap(this.map);
    console.log('site marker adding', ticket, position, this.site_marker);
  }

  getUserName = (user: any) => {
    if (user.first_name) {
      return user.first_name + (user.last_name ? ' ' + user.last_name : '');
    }
    return (
      user.user_first_name +
      (user.user_last_name ? ' ' + user.user_last_name : '')
    );
  };

  enginner_marker: any = null;
  engineedMarkerPosition: any = null;
  makeEngineerMarker(user: any) {
    if (this.active_ticket_site_status && this.active_ticket_site_status > 4)
      return null;

    const userName = this.getUserName(user);
    let position = new google.maps.LatLng(
      this.user_data.latitude,
      this.user_data.longitude
    );
    this.engineedMarkerPosition = position;
    if (!this.enginner_marker) {
      this.enginner_marker = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        content: buildImageIcon(this.user_data.image, userName),
        position: position,
        title: userName,
      });
      const element = this.enginner_marker.element;
      this.enginner_marker.addListener('click', (event: any) => {
        this.setMapCenter(this.engineedMarkerPosition, 20);
      });
    } else {
      this.enginner_marker.setMap(this.map);
      this.enginner_marker.position = position;
      const userImage = this.user_data.image ?? '/assets/icons/maps/engineer.png';
      const new_content = buildImageIcon(
        userImage,
        userName,
        true,
        this.enginner_marker.element
      );

      this.enginner_marker.element.title = userName;
    }
    return true;
  }

  start_marker: any = null;
  startMarkerPosition: any = null;
  startMarker(user: any, position: any) {
    this.startMarkerPosition = position;
    const userName = this.getUserName(user);
    if (!this.start_marker) {
      this.start_marker = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        content:
          this.active_ticket_site_status && this.active_ticket_site_status > 4
            ? buildImageIconCheckIn(this.active_ticket_site_status, userName)
            : buildImageIcon(
                '../../../assets/icons/maps/start_location.svg',
                userName,
                false,
                null,
                'small'
              ),
        position: position,
        title: 'Start point',
      });
      const element = this.start_marker.element;
      this.start_marker.addListener('click', (event: any) => {
        this.setMapCenter(this.startMarkerPosition, 20);
      });
    } else {
      this.start_marker.setMap(this.map);
      this.start_marker.position = position;
      const new_content =
        this.active_ticket_site_status && this.active_ticket_site_status > 4
          ? buildImageIconCheckIn(
              this.active_ticket_site_status,
              userName,
              true,
              this.start_marker.element
            )
          : buildImageIcon(
              '../../../assets/icons/maps/start_location.svg',
              userName,
              true,
              this.start_marker.element,
              'small'
            );

      this.start_marker.element.title = 'Start point';
    }
  }

  socketObj: any = null;
  socket_connected: boolean = false;
  socketConnect: any = null;
  activateSocketConnection = (): any => {
    if (this.socket_connected) {
      return false;
    }
    if (this.socket.isConnected) {
      this.socketObj = this.socket.get_socket();
      this.subsribeForLiveLocation();
    }
    if (!this.socketConnect) {
      this.socketConnect = this.socket.onSocketConnect$.subscribe(
        ({ connected }) => {
          if (connected) {
            console.log('on socket connection');
            this.socketObj = this.socket.get_socket();
            this.subsribeForLiveLocation();
          } else this.unSubscribe();
        }
      );
    }
  };
  subsribeForLiveLocation = () => {
    this.socket_connected = true;
    const roomName = 'user-' + this.user_data.id + '-location';
    this.socket.joinRoom(roomName);
    this.socketObj.on(roomName + 'Message', this.getSocketData);
  };
  getSocketData = (data: any) => {
    this.user_data.latitude = data.latitude;
    this.user_data.longitude = data.longitude;
    if (
      this.active_ticket_site_status !== null &&
      [1, 2, 4].indexOf(this.active_ticket_site_status) >= 0
    ) {
      this.makeEngineerMarker(this.user_data);

      if (
        this.distanceValue &&
        this.start_lat &&
        this.start_lng &&
        distance(
          this.start_lat,
          this.start_lng,
          data.latitude,
          data.longitude,
          'K'
        ) *
          1000 >=
          this.distanceValue * 1.5
      ) {
        console.log('getting new direction data');
        this.calculateAndDisplayRoute(true);
      }
    }
  };
  unSubscribe = () => {
    if (!this.socket_connected) {
      return false;
    }
    this.socket_connected = false;
    const roomName = 'user-' + this.user_data.id + '-location';
    this.socket.leaveRoom(roomName);
    this.socketObj?.removeListener(roomName, this.getSocketData);
    return true;
  };
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.unSubscribe();
    if (this.socketConnect) this.socketConnect.unsubscribe();
  }

  clearMap() {
    this.active_ticket_site_status = null;
    this.distance = null;
    this.duration = null;
    this.setMapCenter(this.centerLatLng.lat, this.centerLatLng.lng, 4.4);
    this.directionsRenderer?.setMap(null);
    this.site_marker?.setMap(null);
    this.enginner_marker?.setMap(null);
    this.start_marker?.setMap(null);
  }
  filteredList = (status_list: any[]) => {
    return status_list.filter((status) => status.active >= 0);
  };
}
