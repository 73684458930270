import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from './constants/api.constants';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
 
  constructor(private http: HttpClient) { }

  public getBotStatus(): Observable<any> {
    return this.http.get('https://0vergtbow6.execute-api.us-east-2.amazonaws.com/bot360/portstatus')
  }

  
  tigetAssignedTask(data: any): Observable<any>
  {
    return this.http.post(`${ API.MEMBER.ASSIGEDNTASK }`, data)
      .pipe(
        ((data) =>
        {
          return data;
        }), (error =>
        {
          return (error);
        })
      );
  }

}
