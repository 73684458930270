import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';
import { UserServiceService } from 'src/app/services/user-service.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TicketsService } from '../tickets.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent {
  displayedColumns: string[] = ['ticket_name', 'site_engineer', 'operator', 'site', 'site_location', 'assigned_task', 'task_status', 'actions'];
  ticketData: any;
  tickets: any = new MatTableDataSource<any>();;
  assigned_tasks: any = [];
  dataLoading: boolean = false;
  mobileView: boolean = false;
  @ViewChild('ticketsPaginator') ticketsPaginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  constructor(public dialog: MatDialog, private userService: UserServiceService, private ticketService: TicketsService, private snackbar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.mobileView = window.outerWidth <= 769;
    this.userService.getTicketData('').subscribe((data: any) => {
      this.ticketData = data;
      this.tickets.paginator = this.ticketsPaginator;
      this.tickets.sort = this.sort;
    })
    this.getTicketsData();
  }
  ngAfterViewInit() {
    this.tickets.paginator = this.ticketsPaginator;
    this.tickets.sort = this.sort;
  }

  openDialog() {

    this.dialog.open(DialogPopupComponent, {
      width: '60%',
      disableClose: true,
      data: {
        form: 'tickets',
        tktData: this.ticketData
      }
    }).afterClosed().subscribe((res) => {
      // add here refgreshing the taska data
      setTimeout(() => {
        this.getTicketsData();
      }, 1000)

    });
  }
  getTicketsData() {
    this.dataLoading = true;
    this.userService.getTickets().subscribe((data: any) => {
      this.tickets.data = data['result'];
      this.dataLoading = false;

      this.tickets.data.forEach((tasks: any) => {
        this.assigned_tasks.push(tasks['assigned_task'])
      });

    }, (error) => {
      this.dataLoading = false;
    })
  }

  editTicket(ticketData: any) {
    this.ticketService.getTicketDatails(ticketData.ticket_id).subscribe((data) => {
      this.dialog.open(DialogPopupComponent, {
        width: '60%',
        disableClose: true,
        data: {
          form: 'tickets',
          tktData: this.ticketData,
          formData: data.result,
          ticketId: ticketData.ticket_id
        }
      }).afterClosed().subscribe((res) => {

        setTimeout(() => {
          this.getTicketsData();
        }, 1000)

      });
    }, (error) => {
      this.snackbar.open(error.message, '', {
        duration: 5000,
        panelClass: ['blue-snackbar']
      });
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tickets.filter = filterValue.trim().toLowerCase();
  }

  showTicket(row: any) {
    this.router.navigate(['/engineer', row.site_engineer_id, 'ticket', row.ticket_id]);
  }

  deleteTicket(ticketData: any) {
    let index = this.tickets.data.findIndex((data: any) => data.ticket_id === ticketData.ticket_id)
    this.dialog.open(WarningDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        message: "Do you want to delete ticket?",
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.ticketService.deleteTicket(ticketData.ticket_id).subscribe((data: any) => {
          console.log({ data });
          this.tickets.data.splice(index, 1)
          this.snackbar.open("Ticket Deleted Successfully", '', {
            duration: 5000,
            panelClass: ['blue-snackbar']
          });
          this.tickets.data = [...this.tickets.data];
        })
      }
    })


  }
}
