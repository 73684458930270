<div class="main-container-wrapper" [class.sidebar-active]="activeSideBar">
  <div class="responsive-menu" (click)="activeSideBar = !activeSideBar"><mat-icon>menu</mat-icon></div>
  <div class="left-side-menu-main-wrapper">
    <div>
      <!--  <div [className]="
          'avatar-wrapper ' + (hideTitles ? 'avatar-wrapper-collapsed' : '')
        ">
        <div class="user-image">
          <img [className]="!hideTitles ? 'avatar-img' : 'avatar-img-small'"
            src="../../../assets/site-engineers/1.jpg" />
        </div>
        <div class="login-user-details">
          <span class="login-user-name">Ronnie Hoffan</span>
          <span class="login-user-designation">Master Admin</span>
        </div>
      </div> -->
      <span class="toggle-icon" (click)="toggleSideBar()"><mat-icon>arrow_left</mat-icon></span>
      <div class="left-side-menu-inner-wrapper">
        <div class="left-menu-wrapper">
          <ng-container *ngFor="let menuItem of menuItems" >
            <div class="left-menu-item" [class.active]="menuItem.isActive" *ngIf="menuItem.hasAccess"
              (click)="selectMenuItem(menuItem)">
              <span class="menu-icon">
                <mat-icon>{{menuItem.icon}}</mat-icon></span>
              <span class="menu-title" *ngIf="!hideTitles">{{
                menuItem.title
                }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!--  <div>
      <div class="devider"></div>
      <div class="left-menu-item logout-wrapper">
        <span class="menu-icon"><img src="assets/icons/dashboard/logout.svg" /></span>
        <span class="menu-title" (click)="logout()" *ngIf="!hideTitles">Logout</span>
      </div>
    </div> -->
  </div>
</div>